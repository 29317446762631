import { createSlice } from "@reduxjs/toolkit";

export const dashboard = createSlice({
    name: "bank",
    initialState: {
        shortStats: [],
        clickSave: {},
        totalEntry: 0,
        filterTerm: '',
    },
    reducers: {
        setShortStats: (state, action) => {
            state.shortStats = action.payload;
        },
        setClickData: (state, action) => {
            state.clickSave = action.payload;
        },

    },
});

export const {
    setShortStats,
    setFilterTerm,
    setClickData,
    setTotalEntry,
    setFilteredList,
} = dashboard.actions;

export const selectShortStats = (state) => state.dashboard.shortStats;
export const selectFilterTerm = (state) => state.dashboard.filterTerm;
export const selectTotalEntry = (state) => state.dashboard.totalEntry;
export const selectFilteredList = (state) => state.dashboard.filteredList;


export default dashboard.reducer;
