

import React from 'react';

import { 
    Title,
    Switch,
    Box, 
    Card
} from '@mantine/core';

const SwitchElement = () =>(
        <Card 
            shadow="sm" 
            p="lg"
            sx={_ => ({
                maxWidth: 450,
                minWidth: 450,
                minHeight: 250,
                '@media (max-width: 1160px)': {
                    minWidth: 'auto',
                    width: 380
                },
                '@media (max-width: 1050px)': {
                    width: '90%',
                    margin: '0 auto'
                }
            })}
        >
            <Title order={3} style={{marginBottom: 30}}>
                Switch
            </Title> 
            <Box 
            sx={ () => ({
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
            })}>
                <Switch size="xs" label="I agree to sell my privacy"/>
                <Switch size="sm" label="I agree to everything" />
                <Switch size="md" aria-label="I agree to everything" />
                <Switch size="lg" onLabel="ON" offLabel="OFF" />
            </Box>
        </Card>
    )

export default SwitchElement
