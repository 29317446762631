
import React from 'react'
import { Box } from '@mantine/core';

import ListingTable from './tab';

import Footer from '../../../../components/Footers/Footer';
         
const Contact = () => (
        <>
            
            <ListingTable />
            {/* <Footer /> */}
        </>
    )

export default Contact
