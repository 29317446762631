import React from 'react';
import { Menu, Button } from '@mantine/core';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Redux-Settings
import { setFilterTerm } from '../../../../redux/mechanicServiceSlice';

const FilterMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const hendleActive = () => {
        dispatch(setFilterTerm("Active"))
    }
    const hendleInActive = () => {
        dispatch(setFilterTerm("Inactive"))
    }
    const justAdd = () => {
        navigate('/admin/add-coupon')
    };

    return (
        <>
            {/* <Menu control={<Button variant="outline" color="dark">More Filter Options</Button>}>
                <Menu.Item onClick={hendleActive}>
                    Active
                </Menu.Item>
                <Menu.Item onClick={hendleInActive}>
                    In Active
                </Menu.Item>
            </Menu> */}

            <Button color="yellow" className='' onClick={() => justAdd()}>Create Coupon</Button>
        </>
    )
}

export default FilterMenu