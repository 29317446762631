import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "./usersSlice";
import userReducer from "./userSlice";
import privacyReducer from "./privacySlice";
import postReducer from "./postSlice";
import mechanicReducer from './mechanicSlice';
import mechanicServiceReducer from './mechanicServiceSlice';
import customerReducer from './customerSlice';
import couponReducer from './couponSlice';
import bookingReducer from './bookingSlice';
import serviceBookingReducer from './serviceBookingSlice';
import contactReducer from './contactSlice';
import aboutReducer from './aboutSlice';
import topupReducer from './topupSlicer';
import complainReducer from './complainSlice';
import bankReducer from './bankSlice';
import sos from './sosSlice';
import planReducer from './planSlice';
import withdrawalsReducer from './withdrawalSlice';
import transactionReducer from './transactionSLice';
import dashboardStats from './dashboard'
import otp from './deleteAccount/otpSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    mechanic: mechanicReducer,
    mechanicService: mechanicServiceReducer,
    customer: customerReducer,
    privacy: privacyReducer,
    posts: postReducer,
    coupons: couponReducer,
    bookings: bookingReducer,
    serviceBooking: serviceBookingReducer,
    contact: contactReducer,
    about: aboutReducer,
    topup: topupReducer,
    complain: complainReducer,
    bank: bankReducer,
    plan: planReducer,
    withdrawals: withdrawalsReducer,
    transaction: transactionReducer,
    dashboard: dashboardStats,
    otp,
    sos
  },
});   
