import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  AppShell,
  Box,
  useMantineTheme
} from '@mantine/core';

import NavBar from "../../../components/Navigations/NavBar";
import AppHeader from '../../../components/Navigations/HeaderMenu';
import Footer from '../../../components/Footers/Footer';

const LayoutPage = () => {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppShell
        padding="md" 
        navbarOffsetBreakpoint="sm"
        navbar={<NavBar opened={opened} />}
        header={<AppHeader opened={opened} setOpened={setOpened} />}
        styles={() => ({
          main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
        })}
      >
        <Box
          style={{ position: 'relative', flex: 1, marginBottom:'0' }}
          sx={() => ({
            '@media (max-width: 800px)': {
              marginTop: '80px'
            }
          })}
        >
          {/* App will render here */}
          <Outlet />
        </Box>
      </AppShell>
      <Footer style={{ flexShrink: 0 }} />
    </div>
  )
};


export default LayoutPage;
