import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const customerListService = ({ countPerPage, page, token }) =>
  axios.get(`${baseUrl}/admin/customer-variable/list?page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const customerCreateService = ( data, {token} ) =>
  axios.post(
    `${baseUrl}/admin/customer-variable/store`, {"data" : data},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

export const customerUpdateStatusService = ({ id, token }) =>
  axios.patch(
    `${baseUrl}/admin/customer-variable/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const customerUpdateService = ({
  id,
  title,
  description,
  webLink,
  token
}) =>
  axios.patch(
    `${baseUrl}/admin/customer-variable/status/${id}`,
    {
      title,
      description,
      type: '',
      webLink,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

export const customerRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/customer-variable/status/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const customerFileUploadService = (data, id, token) =>
  axios.post(`${baseUrl}/healthTips/manage/file?id=${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });