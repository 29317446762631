import React, { useState, useEffect, useCallback } from 'react';
import { TextInput, Textarea, Select, MultiSelect, Card, Grid, Calendar, Box, Button, Modal, Group, Notification } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { useDispatch, useSelector } from 'react-redux';
import { RichTextEditor } from '@mantine/rte';

import ReactQuill, { Quill } from 'react-quill'
// import parse from 'html-react-parser'
import 'react-quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-module-react'
import { ImageDrop } from 'quill-image-drop-module'

import Loading from '../../../../components/Loading/Loading'

// API-Service   
import { CreateDataServiceMechanic, dataListingServiceMechanic } from '../../../../service/aboutService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { selectStateMechanicData, setDataListMechanic, selectStateSingleData } from '../../../../redux/aboutSlice';

const Create = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getStrings = useSelector(selectStateMechanicData);
  const [myStrings, setMyStrings] = useState([]);
  const detail = useSelector(selectStateSingleData);
  const [aboutDescription, setAboutDescription] = useState("");
  const [termsDescription, setTermsDescription] = useState("");
  const [policyDescription, setPolicyDescription] = useState("");
  const navigate = useNavigate();
  const token = getToken();

  const fontSizeArr = ['32px', '40px', '45px']
  const Font = ReactQuill.Quill.import('formats/font')
  const alignClass = ReactQuill.Quill.import('attributors/style/align')
  const backgroundClass = ReactQuill.Quill.import('attributors/style/background')
  const colorClass = ReactQuill.Quill.import('attributors/style/color')
  const directionClass = ReactQuill.Quill.import('attributors/style/direction')
  const fontClass = ReactQuill.Quill.import('attributors/style/font')
  const sizeClass = ReactQuill.Quill.import('attributors/style/size')

  ReactQuill.Quill.register(alignClass, true)
  ReactQuill.Quill.register(backgroundClass, true)
  ReactQuill.Quill.register(colorClass, true)
  ReactQuill.Quill.register(directionClass, true)
  ReactQuill.Quill.register(fontClass, true)
  ReactQuill.Quill.register(sizeClass, true)
  ReactQuill.Quill.register(Font, true)
  ReactQuill.Quill.register('modules/imageResize', ImageResize)
  ReactQuill.Quill.register('modules/imageDrop', ImageDrop)

  sizeClass.whitelist = fontSizeArr
  alignClass.whitelist = ["right", "center", "justify"]
  fontClass.whitelist = ['poppins']

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ color: [] }, { background: [] }],
      // [{ size: ['20px', false, '24px', '32px'] }],
      [{ size: sizeClass.whitelist }],
      [{ font: [] }],
      // [{ align: alignClass.whitelist }],
      ["link", "image", "video"],
      ["clean"]
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      // parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  }

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "indent",
    'link',
    'image',
    'color',
    'size',
    'video',
    'align',
    'background',
    'direction',
    'code-block',
    'code'
  ]

  const form = useForm({
    initialValues: {
      about: '',
      term: '',
      privacy: '',
    },
    // validate: {
    //     about: (value) => (value.length < 2 ? 'Please enter about description' : null),
    //     term: (value) => (value.length < 2 ? 'Please enter terms description' : null),
    //     privacy: (value) => (value.length < 2 ? 'Please enter privacy description' : null),
    //   },
  });

  const fetchData = async () => {
    const result = await dataListingServiceMechanic({ token });
    if (result?.data?.status) {
      dispatch(setDataListMechanic(result?.data?.data?.mechanic_variables_list));
    }
  };

  useEffect(() => {
    // setFromValue(getStrings[0]?.value)
    if (getStrings.length > 0 && getStrings[0]?.name === 'about_us') {
      setAboutDescription(getStrings[0]?.value)
      setPolicyDescription(getStrings[2]?.value)
       setTermsDescription(getStrings[3]?.value)
    } else if (getStrings.length > 0 && getStrings[3]?.name === 'terms_and_conditions') {
      // setTermsDescription(getStrings[3]?.value)
    }
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [detail]);

  useEffect(() => {
    fetchData();

    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleCreate = async (e, values) => {

    e.preventDefault()
    // console.log('input', myStrings)
    const strings = {
      data: [myStrings]
    }
    const result = await CreateDataServiceMechanic({ token, }, strings);

    if (result?.data?.status) {
      SuccessToast(result?.data?.message)
      const resultAll = await dataListingServiceMechanic({ page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        form.reset();
        dispatch(setDataListMechanic(resultAll?.data.data?.mechanic_variables_list));
        setLoading(false);
        navigate('/admin/mechanic/string/list');
        form.reset();
      } else {
        setLoading(false);
        ErrorToast(resultAll.data?.message);

      }
    }
  };
  const hendleCancel = () => {
    navigate('/admin/string');
  };

  const handleAboutChange = (content, delta, source, editor) => {
    setAboutDescription(content);
    // const removeTag = e.replace(/(<([^>]+)>)/ig, '');
    const removeTag = content;
    setMyStrings((prevState) => ({
      ...prevState,
      name: "about_us",
      value: removeTag
    }));
  };
  const handleTermsChange = (content, delta, source, editor) => {
    setTermsDescription(content);
    // const removeTag = e.replace(/(<([^>]+)>)/ig, '');
    const removeTag = content;
    setMyStrings((prevState) => ({
      ...prevState,
      name: "terms_and_conditions",
      value: removeTag
    }));
  };
  const handlePrivacyChange = (content, delta, source, editor, e) => {
    setPolicyDescription(content);
    // const removeTag = e.replace(/(<([^>]+)>)/ig, '');
    const removeTag = content;
    setMyStrings((prevState) => ({
      ...prevState,
      name: "privacy_policy",
      value: removeTag
    }));
  };

  const handleAbout = (e) => {
    const removeTag = e.replace(/(<([^>]+)>)/ig, '');
    setMyStrings((prevState) => ({
      ...prevState,
      name: "about_us",
      value: removeTag
    }));
  };
  const handleTerms = (e) => {
    const removeTag = e.replace(/(<([^>]+)>)/ig, '');
    setMyStrings((prevState) => ({
      ...prevState,
      name: "terms_and_conditions",
      value: removeTag
    }));
  };

  return (
    <Card>
      <Grid>
        <Grid.Col span={12}><h2>Mechanic Strings</h2></Grid.Col>
      </Grid>
      <form onSubmit={handleCreate}>
        <Grid>
          <Grid.Col span={12}>
            <h3>
              {(() => {
                if (detail.name === 'about_us') {
                  return 'About Us';
                } if (detail.name === 'privacy_policy') {
                  return 'Privacy Policy';
                }
                return 'Terms & Conditions';
              })()}
            </h3>
            <ReactQuill
              style={{ minHeight: 300 }}
              theme="snow"
              modules={modules}
              formats={formats}
              value={(() => {
                if (detail.name === 'about_us') {
                  return aboutDescription;
                } if (detail.name === 'privacy_policy') {
                  return policyDescription;
                }
                return termsDescription;
              })()}
              onChange={(() => {
                if (detail.name === 'about_us') {
                  return handleAboutChange;
                } if (detail.name === 'privacy_policy') {
                  return handlePrivacyChange;
                }
                return handleTermsChange;
              })()}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="left" mt="md">
              <Button color="dark" onClick={hendleCancel}>Cancel</Button>
              <Button color="yellow" type="submit">Save</Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    </Card>
  );
  
};

export default Create;
