
import React from 'react';
import { Tabs, Title, Card } from '@mantine/core';
import { Photo, MessageCircle, Settings } from 'tabler-icons-react';

const TabElement = () =>(
        <Card 
            shadow="sm" 
            p="lg"
            sx={_ => ({
                maxWidth: 450,
                minWidth: 450,
                minHeight: 250,
                '@media (max-width: 1160px)': {
                    minWidth: 'auto',
                    width: 380
                },
                '@media (max-width: 1050px)': {
                    width: '90%',
                    margin: '0 auto'
                }
            })}
        >
            <Title order={3} style={{marginBottom: 30}}>
                Tab
            </Title> 
            <Tabs>
                <Tabs.Tab label="Gallery" icon={<Photo size={14} />}>Gallery tab content</Tabs.Tab>
                <Tabs.Tab label="Messages" icon={<MessageCircle size={14} />}>Messages tab content</Tabs.Tab>
                <Tabs.Tab label="Settings" icon={<Settings size={14} />}>Settings tab content</Tabs.Tab>
            </Tabs>
        </Card>
    )

export default TabElement