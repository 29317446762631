import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const dataListingService = ({ page, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/coupon/list?paginate=1&page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
export const userSearchService = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/coupon/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  
export const CreateDataService = ({ token }, formData) =>
  axios.post(
    `${baseUrl}/admin/coupon/store`, formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

export const UpdateStatusService = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/coupon/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

export const getSingleDataService = ({ id, token }) =>
  axios.get(`${baseUrl}/admin/coupon/single/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });


export const UpdateSingleDataService = (
  { token, id },
  formData,
  // const id = formData.get('id')
) =>
  axios.post(
    `${baseUrl}/admin/coupon/update/${id}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data',
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const dataRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/coupon/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const FileUploadService = (data, id, token) =>
  axios.post(`${baseUrl}/healthTips/manage/file?id=${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });