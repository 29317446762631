import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const bankListSevice = ({   countPerPage, page, token }) =>
  axios.get(`${baseUrl}/admin/bank/list?paginate=1&page=${page}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });   

export const bankSearchListSevice = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/bank/list?keyword=${search}&paginate=1&&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const addBankService = ({ token }, formData) =>
  axios.post(
    `${baseUrl}/admin/bank/store`, formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

export const bankStatusChange = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/bank/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const bankUpdateData = (
  { token },
  formData,
  // const id = formData.get('id')
) =>
  axios.post(
    `${baseUrl}/admin/bank/update/${formData.get('id')}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data',
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const bankDelete = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/bank/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const mechanicFileUploadService = (data, id, token) =>
  axios.post(`${baseUrl}/healthTips/manage/file?id=${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });