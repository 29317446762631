import axios from "axios" 
 
const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT


export const genrateOtpService = (inputs) => axios.post(`${baseUrl}/admin/customer-delete/generate-otp`,
inputs,
{
    headers: {
        Accept: 'application/json',
        // Accept: 'multipart/form-data',
        // 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    }
})

export const otpVerficationService = (inputs) => axios.post(`${baseUrl}/admin/customer-delete/verify-otp`,
inputs,
{
    headers: {
        Accept: 'application/json',
        // Accept: 'multipart/form-data',
        // 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    }
})

export const deleteAccountService = (inputs) => axios.post(`${baseUrl}/admin/customer-delete/account`,
inputs,
{
    headers: {
        Accept: 'application/json',
        // Accept: 'multipart/form-data',
        // 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    }
})