import { createSlice } from "@reduxjs/toolkit";
 
export const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState: {
    MechanicWithdrawals: [],
    MechanicTotalEntry: 0 , 
    FreelancerTotalEntry: 0 , 
    FreelancerPlans: [],
  },       
  reducers: {      
    setMechanicPlansList: (state, action) => {
      state.MechanicWithdrawals = action.payload;
    },
        setMechanicTotalEntry: (state, action) => {
        state.MechanicTotalEntry = action.payload;
    },
    setFreelancerPlansList: (state, action) => {
      state.FreelancerPlans = action.payload;
    },
    setFreelancerTotalEntry: (state, action) => {
      state.FreelancerTotalEntry = action.payload;
  },

  },
});  
 
export const {
    setMechanicPlansList, 
    setFreelancerPlansList,
    setFreelancerTotalEntry,
    setMechanicTotalEntry,

} = withdrawalSlice.actions;

export const selectStateMechanicPlans = (state) => state.plan.MechanicWithdrawals; 
export const selectStateFreelancerPlans = (state) => state.plan.FreelancerPlans;
export const selectFreelancerTotalEntry = (state) => state.plan.FreelancerTotalEntry; 
export const selectMechanicTotalEntry = (state) => state.plan.MechanicTotalEntry; 

export default withdrawalSlice.reducer;
