/* eslint-disable react/no-unescaped-entities, no-nested-ternary, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Row, Col, Label } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
// import toast from 'react-hot-toast'
import { otpVerficationService, genrateOtpService } from '../../../../service/otpVerification/otpService' // Assuming you have an OTP verification service
import { selectPhoneNumber, setotpNumber } from '../../../../redux/deleteAccount/otpSlice'
import Logo from '../../../../assets/images/logo.png'
import { ErrorToast, SuccessToast } from '../../../../utils/utilities'

const VerificationPage = () => {
    const navigate = useNavigate()
    const [phoneNumber, setPhoneNumber] = useState('')
    const [loading, setLoading] = useState(false)
    const [otpInputs, setOtpInputs] = useState(['', '', '', ''])
    const PhoneNumber = useSelector(selectPhoneNumber)
    console.log('phone number', PhoneNumber)
    const dispatch = useDispatch()
    const [resendDisabled, setResendDisabled] = useState(true)
    const [resendTimer, setResendTimer] = useState(60) // Initial value in seconds
    // ... (other state variables)
    const inputRefs = Array.from({ length: 4 }, () => useRef(null))


    // Effect to decrement the timer every second
    useEffect(() => {
        let interval

        console.log('Setting up interval:', resendTimer, resendDisabled)

        if (resendTimer > 0 && resendDisabled) {
            interval = setInterval(() => {
                setResendTimer((prevTimer) => prevTimer - 1)
            }, 1000)
        }

        return () => {
            console.log('Clearing interval')
            clearInterval(interval)
        }
    }, [resendTimer, resendDisabled])


    const handleOtp = async () => {
        setLoading(true)

        const otpValue = otpInputs.join('')
        if (!otpValue.trim()) {
              ErrorToast('Please fill data in the OTP field.')
            return
        }

        try {
            const result = await otpVerficationService({ phone: PhoneNumber, otp: otpValue })

            if (result?.data?.status === 'success') {
                setLoading(false)
                dispatch(setotpNumber(otpValue))
                SuccessToast('OTP verfied successfully')
                navigate('/delete-verification')

            } else {
                setLoading(false)
                // toast.error('Incorrect OTP. Please try again.')
            }
        } catch (error) {
            setLoading(false)
            console.error('Error during OTP verification:', error)
             ErrorToast('Failed to verify OTP. Please try again.')
        }
    }

    const handleResend = async () => {
        try {
            setLoading(true)

            const inputs = {
                phone: PhoneNumber
            }

            // Assuming you have a separate service for OTP resend
            const result = await genrateOtpService(inputs).catch((error) => {
                setLoading(false)
                ErrorToast(error.response.data.message)
            })

            if (result.data.status === 'success') {
                setLoading(false)
                SuccessToast('OTP resent successfully')
                setResendDisabled(true)
                setResendTimer(60)
            } else {
                setLoading(false)
                ErrorToast(result.data.message)
            }
        } catch (error) {
            setLoading(false)
            console.error('Error during OTP resend:', error)
            ErrorToast('Failed to resend OTP. Please try again.')
        }
    }


    const handleInputChange = (index, value) => {
        const newOtpInputs = [...otpInputs]
        newOtpInputs[index] = value
        setOtpInputs(newOtpInputs)

        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus()
        }
    }

    const handleCancel = () => {
        navigate('/delete-account')
    }


    return (
        <>
            <Row>
                <Col>
                    <div className="p-3">
                        <div className="d-flex flex-column align-items-center justify-content-center text-center">
                            <img src={Logo} alt="logo" width="70px" height="70px" />
                            <h2 className="mt-2">
                                <span className="d-block d-md-inline">Arc </span>
                                <span className="d-block d-md-inline">App</span>
                            </h2>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='m-1 mt-50'>
                <div className="d-flex justify-content-center mt-1">
                    <div className="bg-white p-5 d-flex flex-column align-items-center justify-content-center shadow col-lg-5 col-md-8 col-12">
                        <div>
                            <h5 className="fw-bold mt-2 mb-0 text-center ">Please check your Message</h5>
                            <p className='text-center mb-0 mt-0 font-resend'>We've sent a code to +6 {PhoneNumber}</p>
                        </div>
                        <Row className="mb-1 mt-1">
                            {Array.from({ length: 4 }, (_, index) => (
                                <Col key={index} xs="auto" className="p-0 me-1">
                                    <Input
                                        className="otp-field rounded-3 mb-2 text-center"
                                        type="number"
                                        value={otpInputs[index]}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.target.value.length === 1) e.preventDefault()
                                        }}
                                        innerRef={inputRefs[index]}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <div>
                            <p className='font-resend'>
                                Did not get code{' '}
                                {resendDisabled && resendTimer > 0 ? (
                                    <span>
                                        Resend in {resendTimer} seconds
                                    </span>
                                ) : (
                                    <strong onClick={handleResend} style={{ cursor: 'pointer' }}>
                                        Click to resend
                                    </strong>
                                )}
                            </p>
                        </div>

                        <Row className='mt-50'>
                            <Col xs={12} lg={6} className='mb-2 mb-lg-0'>
                                <Button
                                    color="outline-secondary"
                                    className="col-12"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={12} lg={6} className="mb-2 mb-lg-0">
                                <Button
                                    color="primary"
                                    className="col-12"
                                    onClick={handleOtp}
                                >
                                    Verify
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Row>
        </>
    )
}

export default VerificationPage

