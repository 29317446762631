import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, UseFormReturnType } from '@mantine/form';
import { Box, Card, Image, PasswordInput, TextInput, Button, Title, Group, Grid } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

// Utility-Service 
import { getToken, setUserSession } from '../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../utils/utilities';

import { userUpdatePassword, userGetCurrentPassword } from '../../service/user'
import { setUserPassword } from '../../redux/userSlice';

import Loading from '../Loading/Loading';

/*
    Props-Function: 
    1. logo props for logo-image path
        > Value-type: string
    2. LogoVariantInside for set logo in inside of Card Component
        >  Value-type: Boolean
    2. LogoVariantOutside for set logo in outside of Card Component
        >  Value-type: Boolean
*/

const Login = ({ logo = null, LogoVariantInside = false, LogoVariantOutside = false, shadow = 'lg' }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = getToken();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const getData = useSelector(store => store.user?.userPassword)
    const form = useForm({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            passwordConfirmation: ''
        },
        validate: {
            oldPassword: (value) => (value.length < 2 ? 'Please enter old password' : null),
            newPassword: (value) => (value.length < 2 ? 'Please enter new password' : null),
            passwordConfirmation: (value) => (value.length < 2 ? 'Please enter repeat new password' : null),
        },

    });

    const handlePassword = async (values, e) => {
        // e.preventDefault();
        setLoading(true);
        const result = await userUpdatePassword({
            token,
            old_password: values.oldPassword,
            password: values.newPassword,
            password_confirmation: values.passwordConfirmation,
        })
        if (result?.data?.status) {
            setLoading(false);
            SuccessToast(result.message);
            dispatch(setUserPassword(result.data))
            navigate('/admin/dashboard');
        } else {
            setLoading(false)
            ErrorToast(result);
        }
        return false;
    }

    // useEffect(() => {
    //     setLoading(true);
    //     const fetchPasswordData = async () => {
    //         const result = await userGetCurrentPassword(
    //             {token},
    //             {setError,setLoading});
    //         if (result?.status) {
    //             const passwordData = result?.data
    //             dispatch(setUserPassword(passwordData.data))
    //         }
    //     }
    //     fetchPasswordData();
    //     const timeoutId = setTimeout(() => setLoading(false), 3000);
    //     return function cleanup() {
    //         clearTimeout(timeoutId);
    //     }
    // }, [])

    if (loading) {
        return <Card>
            <div style={{
                margin: 'auto',
                textAlign: 'center'
            }}><Loading /></div>
        </Card>
    }

    return (
        <Box style={{ width: '100%' }}>
            {logo && LogoVariantOutside && (
                <Image
                    style={{
                        width: '110px',
                        margin: '50px auto'
                    }}
                    src={logo}
                    alt="Dashboard Logo"
                />
            )}
            <Card style={{ width: '100%' }} shadow={!shadow ? shadow : 'lg'} padding="xl">
                <Title
                    style={{
                        margin: '14px auto',
                        textAlign: 'left'
                    }}
                    order={3}
                >
                    Change Password
                </Title>

                <form onSubmit={form.onSubmit((values, e) => handlePassword(values, e))}>
                    <Grid>
                        <Grid.Col span={4}>
                            <PasswordInput
                                label="Old Password"
                                placeholder="Old Password"
                                autoComplete="off"
                                mt="sm"
                                {...form.getInputProps('oldPassword', {
                                    required: true,
                                })}
                            />
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PasswordInput
                                label="New Password"
                                placeholder="New Password"
                                autoComplete="on"
                                mt="sm"
                                {...form.getInputProps('newPassword', {
                                    required: true,
                                })}
                            />
                        </Grid.Col>

                        <Grid.Col span={4}>
                            <PasswordInput
                                label="Password Confirmation"
                                placeholder="Password Confirmation"
                                autoComplete="on"
                                mt="sm"
                                {...form.getInputProps('passwordConfirmation', {
                                    required: true,
                                })}
                            />
                        </Grid.Col>
                        <Group position="left" mt="sm">
                            <Button
                                type="reset"
                                variant="filled"
                                color="dark"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="filled"
                                type="submit"
                                color="orange"
                            >
                                Save
                            </Button>
                        </Group>
                    </Grid>
                </form>
            </Card>
        </Box>
    );
};

export default Login;

// Login.prototypes = {
//     children: PropTypes.string
// };
