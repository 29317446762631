import React, { useState, useCallback } from 'react';
import { TextInput, Textarea, Select, MultiSelect, Card, Grid, Calendar, Box, Button, Modal, Group, Notification } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
  

const Create = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectUser = () => {
    navigate('/admin/user/string/list');
  };
  const selectMechanic = () => {
    navigate('/admin/mechanic/string/list');
  };

  return (
    <Card >
          <Grid>
            <Grid.Col span={12}>
              <Group position="center" mt="md" style={{ minHeight: 500 }}>
                <Button size="lg" color="dark" type="submit" onClick={selectUser}>
                   User
                </Button>
                <Button size="lg" color="yellow" type="submit" onClick={selectMechanic}>Mechanic</Button>
              </Group>
            </Grid.Col>



          </Grid>


      </Card>
  );
};

export default Create;
