import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    userList: [],
    totalEntry: null,
    filteredList: [],
    filterTerm: '',
    userProfile: null,
    userPassword: null,
  },    
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    setLoginDetails: (state, action) => {
      state.user = action.payload;
    }, 
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    updateUserProfile: (state, action) => {
      state.user.userProfile.name = action.payload.name;
      state.user.userProfile.email = action.payload.email;
      state.user.userProfile.phone = action.payload.phone;
    },

    setUserPassword: (state, action) => {
      state.user = action.payload;
    },
    updateUserPassword: (state, action) => {
      state.user.userPassword.OldPassword = action.payload.OldPassword;
      state.user.userPassword.newPassword = action.payload.newPassword;
      state.user.userPassword.passwordConfirmation = action.payload.passwordConfirmation;
    },

    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload;
  },
  setFilterTerm: (state, action) => {
    state.filterTerm = action.payload;
  },
    // createUser: (state, action) => {
    //   state.userList.push(action.payload);
    // },
    // updateUser: (state, action) => {
    //   state.userList.map(item => {
    //     if(item.id === action.payload.id){
    //       item.roles = action.payload.roles
    //     }
    //     return true;
    //   });
    // },
    // deleteUser: (state, action) => {
    //   state.userList = state.userList?.filter(item => item.id !== action.payload);
    // }

    
  }, 
});

export const {
  login, 
  logout, 
  setLoginDetails,
  setUserProfile,
  setFilteredList,
  updateUserProfile, 
  setUserPassword,
  updateUserPassword,
  setUserList, 
  setTotalEntry,
  setFilterTerm,
  // createUser, 
  // updateUser, 
  // deleteUser,
  userProfile
} = userSlice.actions;

export const selectUser = (state) => state.user; 
export const selectUserList = (state) => state.user.userList; 
export const selectFilterTerm = (state) => state.user.filterTerm; 
export const selectTotalEntry = (state) => state.user.totalEntry; 
export const selectUserProfile = (state) => state.user.userProfile; 
export const selectFilteredList = (state) => state.user.filteredList; 

export default userSlice.reducer;
