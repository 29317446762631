import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { Box, Card, Image, TextInput, Button, Title, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { loginService } from '../../service/API'

/*
    Props-Funcation: 
    1. logo props for logo-image path
        > Value-type: string
    2. LogoVariantInside for set logo in inside of Card Component
        >  Value-type: Boolean
    2. LogoVariantOutside for set logo in outside of Card Component
        >  Value-type: Boolean
*/

const LoginForm = ({
    logo = null,
    LogoVariantInside = false,
    LogoVariantOutside = false,
    shadow = 'lg'
}) => {
    const navigate = useNavigate();
    const form = useForm({
        initialValues: {
            email: '',
            password: ''
        },
    });

    const handleLogin = (values) => {
        // console.log(values)
        loginService(navigate);
    }

    return (
        <Box style={{ width: '100%' }} >
            {logo && LogoVariantOutside &&
                <Image
                    style={{
                        width: '110px',
                        margin: '50px auto',
                    }}
                    src={logo}
                    alt="Dashboard Logo"
                />
            }
            <Card style={{ width: '100%' }} shadow={!shadow ? shadow : 'lg'} padding="xl">
                {logo && LogoVariantInside &&
                    <Image
                        style={{
                            width: '110px',
                            margin: '30px auto',
                        }}
                        src={logo}
                        alt="Dashboard Logo"
                    />
                }
                {logo && !LogoVariantInside && !LogoVariantOutside &&
                    <Image
                        style={{
                            width: '110px',
                            margin: '30px auto',
                        }}
                        src={logo}
                        alt="Dashboard Logo"
                    />
                }

                <Title style={{
                    margin: '14px auto',
                    textAlign: 'center'
                }} order={3}>Sign In</Title>

                <form onSubmit={form.onSubmit((values) => handleLogin(values))}>
                    <TextInput
                        required
                        label="Email"
                        placeholder="example@mail.com"
                        {...form.getInputProps('email')}
                    />
                    <TextInput
                        required
                        label="Password"
                        placeholder="Password"
                        type="password"
                        mt="sm"
                        {...form.getInputProps('password')}
                    />
                    <Group position="right" mt="xl">
                        <Button variant="filled"
                            type="submit"
                            styles={(theme) => ({
                                root: {
                                    transition: 'all 0.3s ease-in',
                                    marginTop: 20,
                                    '&:hover': {
                                        backgroundColor: theme.fn.darken('#228be6', 0.05),
                                    },
                                }
                            })}
                            fullWidth
                        >
                            Sign In
                        </Button>
                    </Group>
                </form>
            </Card>
        </Box>
    );
}

export default LoginForm

LoginForm.prototypes = {
    children: PropTypes.string
}