import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const transactionList = ({ page, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/transactions/customer/transaction-list?paginate=1&page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
export const transactionSearchList = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/transactions/customer/transaction-list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const transactionIsResolve = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/transactions/customer/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const transactionDelete = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/transactions/customer/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );