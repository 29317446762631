import { createSlice } from "@reduxjs/toolkit";

export const topupSlice = createSlice({
  name: "coupon",
  initialState: {
    dataListing: [],
    filteredList: [],
    filterTerm: '',
    isPopup: false,
    myFile: '',
    clickSave: {},
    singleData: {},
    totalEntry: 0
  },
  reducers: {
    setDataList: (state, action) => {
      state.dataListing = action.payload;
    },
    setPopup: (state, action) => {
      state.isPopup = action.payload;
    },
    setSingleData: (state, action) => {
      state.singleData = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },

    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },


  },
});

export const {
  setDataList,
  setSingleData,
  setFilterTerm,
  setTotalEntry,
  setPopup,
} = topupSlice.actions;

export const selectStateData = (state) => state.topup.dataListing;
export const selectFilterTerm = (state) => state.topup.filterTerm;
export const selectTotalEntry = (state) => state.topup.totalEntry;
export const selectIsPopup = (state) => state.topup.isPopup;
export const selectStateDetail = (state) => state.topup.clickSave;
export const selectStateSingleData = (state) => state.topup.singleData;


export default topupSlice.reducer;
