
import React from 'react';
import { Box, Image } from '@mantine/core';
import PropTypes from 'prop-types';

import LoginForm from '../../../components/Forms/LoginForm'

// Images
import Logo from "../../../assets/images/Icon.png"
import loginScreen01 from "../../../assets/images/loginScreen01.png"

/*
    Props-Funcation: 
    1. displayReverse props for changing  page content alignment
        > Value-type: Boolean
*/

const LoginPageV2 = ({displayReverse}) => (
    <Box sx={_ => {
        if(displayReverse){
            return ({
                display: 'flex',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
                "flex-direction": 'row-reverse',
                position: 'relative'
            })
        }
        return ({
            display: 'flex',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            position: 'relative'
        })
    }}
    >   
        <Box sx={_ => ({
                width: '70%',
                height: '100vh',
                '@media (max-width: 1600px)': {
                    width: '60%',
                },
                '@media (max-width: 912px)': {
                    width: '100%',
                }
            })}
        >
            <Image height='100vh' src={loginScreen01} alt="Screen" />
        </Box>

        <Box sx={_ => ({
                margin: '0 auto',
                height: '100vh',
                '@media (max-width: 912px)': {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    margin: '0 auto'
                }
            })}>
            <Box sx={_ => ({
                width: 440,
                height: '100vh',
                margin: 'auto',
                display: 'flex',
                alignItems: "center",
                '@media (max-width: 912px)': {
                    width: '80%',
                },
                '@media (max-width: 512px)': {
                    width: '90%',
                }
            })}>
                <LoginForm 
                    logo={Logo}
                    LogoVariantInside
                />
            </Box>
        </Box>
    </Box>
)

export default LoginPageV2

LoginPageV2.prototypes = {
    children: PropTypes.string
}