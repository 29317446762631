import React, { useState, useEffect } from 'react';
import { Box, Image, Switch, Badge } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";


import Loading from '../../../../components/Loading/Loading';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Delete and Update
import DeleteUpdate from './DeleteUpdate';

// API-Service
import { transactionList, transactionIsResolve } from '../../../../service/transactionService';

// Redux-Settings
import {
  setTransactionList,
  setTotalEntry,
  // selectFilterTerm,
  selectStateTransaction,
  selectTotalEntry
} from '../../../../redux/transactionSLice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';


const ListingTable = () => {
  // const filterTag = useSelector(selectFilterTerm);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const Listing = useSelector(selectStateTransaction);
  const totalEntry = useSelector(selectTotalEntry);

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (page) => {
    const result = await transactionList({ page, countPerPage, token });
    if (result?.data?.status) {
      const responseData = result?.data?.data?.transaction_lists
      setLoading(false);
      dispatch(setTransactionList(responseData));
      // dispatch(setFilteredList(responseData));
      dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  };

  const statusUpdate = async (rowRecord) => {
    const statusUpdateresult = await transactionIsResolve({ id: rowRecord.id, token });
    if (statusUpdateresult?.data?.status) {
      fetchData(pageCount);
      SuccessToast(statusUpdateresult.data.message)
    } else {
      ErrorToast(statusUpdateresult);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(pageCount);
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [pageCount]);

   // ** Function to handle Pagination
   const handlePagination = page => {
    setCurrentPage(page.selected)
}

   // ** Custom Pagination
   const CustomPagination = () => (
    <ReactPaginate
        nextLabel={<div className='d-inline-flex align-items-center bg-white p-50 border border-2 rounded p-1'>
            <span className='fs-14 fw-bold me-2 text-dark '>Next</span>
            <AiOutlineArrowRight className='text-secondary' size={18} />
        </div>}
        breakLabel='...'
        previousLabel={<div className='d-inline-flex align-items-center bg-white p-50  border border-2 rounded p-1'>
            <AiOutlineArrowLeft className='text-secondary me-2' size={18} />
            <span className='fs-14 fw-bold text-dark' >Previous</span>
        </div>}
        pageRangeDisplayed={3}
        forcePage={currentPage}
        marginPagesDisplayed={3}
        activeClassName='active bg-light border rounded px-2 d-flex align-items-center text-dark border-3'
        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
        breakClassName='page-item me-3 d-flex align-items-center'
        nextClassName='page-item next-item flex-grow-1 text-end'
        previousClassName='page-item prev-item flex-grow-1 me-3'
        disabledLinkClassName='btn disabled p-0 border-0'
        pageCount={Math.ceil(totalEntry / 10) || 1}
        onPageChange={page => handlePagination(page)}
        containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-2 ps-1'
    />
)

  const columns = [
    {
      name: 'Booking ID',
      selector: (row) => row.booking_id ? row.booking_id : '--',
      minWidth: '40px'
    },
    // {
    //   name: 'Profile',
    //   selector: (row) => (
    //     row?.profile_image ? (
    //       <AuthImage src={row?.profile_image} />
    //     ) : (
    //       <Image
    //         src={PlaceHolderThumb}
    //         alt="Avatar Thumb"
    //         sx={(_) => ({
    //           height: '60px',
    //           width: '60px',
    //           overflow: 'hidden',
    //         })}
    //       />
    //     )),
    // },

    {
      name: 'Name',
      selector: (row) => row.customer?.name ? row.customer?.name : '--',
      minWidth: '100px'
    },
   
    {
      name: 'Phone',
      selector: (row) => row.customer?.phone ? row.customer?.phone : '--',
      sortable: true,
      minWidth: '105px'
    },
    {
      name: 'Extra Payment',
      selector: (row) => row.is_extra_payment === true ? 'YES' : 'NO',
      sortable: true,
      minWidth: '50px'
    },
    {
      name: 'Top Up',
      selector: (row) => row.top_up ? row.top_up : '--',
      sortable: true,
      minWidth: '50px'
    },
    {
      name: 'Previous Amount',
      selector: (row) => row.previous_amount ? `RM ${row.previous_amount}` : '--',
      sortable: true,
    },
    {
      name: 'Updated Amount',
      selector: (row) => row.updated_amount ? `RM ${row.updated_amount}` : '--',
      sortable: true,
    },
    {
      name: 'Transaction Type',
      selector: (row) => row.transaction_type ? row.transaction_type : '--',
      sortable: true,
      minWidth: '130px'
    },
    {
      name: 'Transaction Status',
      selector: (row) => {
        let showData = ''
        if (row.transaction_status === 'ONGOING') {
          showData = <Badge color="indigo">ONGOING</Badge>
        }
        else if (row.transaction_status === 'TOWED') {
          showData = <Badge color="violet">TOWED</Badge>
        }
        else if (row.transaction_status === 'COMPLETED') {
          showData = <Badge color="green">COMPLETED</Badge>
        }
        else if (row.transaction_status === 'RELEASE_MECHANIC') {
          showData = <Badge color="orange">RELEASE MECHANIC</Badge>
        }
        return (showData)
      },
    },

    {
      name: 'Status',
      //   onChange={() => statusUpdate(row)}
      selector: (row) => (
        <Switch
          checked={row.status === 1}
          onChange={() => statusUpdate(row)}
          onLabel="ON"
          offLabel="OFF"
          size="md"
          color="yellow"
        />
      ),
      minWidth: '70px',
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      minWidth: '100px',
    },

  ]; 

  if (loading) {
    return (
      <Box
      sx={() => ({
        margin: '0 auto',
        minHeight: 440,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      })}
    >
      <span className="visually-hidden"><Loading /></span>
    </Box>
    );
  }

  return (
    <Box
      sx={() => ({
        minHeight: '100vh',
        '.rdt_TableCol_Sortable div:first-of-type': {
          whiteSpace: 'initial',
          textOverflow: 'initial',
          overflow: 'visible'
        },
        '.rdt_TableCell div:first-of-type': {
          whiteSpace: 'initial',
          padding: '5px 0'
        },
        '@media (max-width: 820px)': {
          overflow: 'scroll',
        },
      })}
    >
      <Box className="rui-filemanager-content">
        <Box className="table-responsive-lg inner-content-fix">
          <DataTable
            className="rui-datatable rui-filemanager-table table mt-10"
            columns={columns}
            data={Listing}
            key={Listing?.id}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={totalEntry}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={(page) => setPageCount(page)}
            paginationComponent={CustomPagination}
            paginationDefaultPage={currentPage + 1} 
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;
