import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Card, Menu, Button, Image, Textarea, Modal, Group, Box, TextInput, Notification, ActionIcon } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons';
import { FiEdit, FiTrash, FiEye, FiMoreVertical } from "react-icons/fi";


// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';


import ListingTable from './ListingTable';
import ServiceComplain from './ServiceComplain'

const MyTabs = () => {
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const navigate = useNavigate();
    const token = getToken();


    return (
        <>
            <Card>
                <Tabs color="teal" defaultValue="first">
                <Tab label="BreakDown Complaint">
                <ListingTable/>
                </Tab>
                <Tab label="Service Complaint">
                <ServiceComplain/>
                </Tab>
                </Tabs>
            </Card>
        </>
    );
};

export default MyTabs;
