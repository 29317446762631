import React, { useState, useEffect } from 'react';

import { Avatar, Divider, Box, Image, Switch, Card, Grid } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'
import moment from 'moment';

import { FiLink, FiEdit, FiTrash, FiEye, FiMoreVertical, } from "react-icons/fi";

import Loading from '../../../../components/Loading/Loading';


// Utility-Service
import { getToken } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service
import { serviceDetail, UpdateStatusService } from '../../../../service/bookingService';

// Redux-Settings
import {
    setSingleData,
    selectStateData,
    selectStateSingleData
} from '../../../../redux/bookingSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';


const BookingDetail = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const getBookingDetail = useSelector(selectStateSingleData);
    const { id } = useParams()
    console.log('in view ', getBookingDetail)
    const token = getToken();

    const getSingleData = async () => {
        const result = await serviceDetail({ id, token });
        if (result?.data.status) {
            setLoading(false);
            dispatch(setSingleData(result?.data.data))
        } else {
            setLoading(false);
            ErrorToast(result);
        }
    };
    useEffect(() => {
        getSingleData();
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    const statusUpdate = async (item) => {
        const statusUpdateresult = await UpdateStatusService({ id: item.id, token });
        if (statusUpdateresult?.data?.status) {
            ErrorToast(statusUpdateresult?.data?.message);
            SuccessToast()
        } else {
            ErrorToast(statusUpdateresult);
        }
    };


    if (getBookingDetail.booking_code) {
        return (
            <Card>
                <Grid>
                    <Grid.Col span={12}>
                        <h3>Booking Detail</h3>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Avatar variant="outline" size="xl" color="violet" component={Link} to="/my-route/" src={getBookingDetail?.customer?.profile_image} />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Name</p>
                            <Divider my="sm" />
                            <h6>{getBookingDetail?.customer.name ? getBookingDetail?.customer.name : '-'}</h6>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Email</p>
                            <Divider my="sm" />
                            <h6>{getBookingDetail?.customer?.email ? getBookingDetail?.customer?.email : '-'}</h6>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Phone</p>
                            <Divider my="sm" />
                            <h6>{getBookingDetail?.customer?.phone ? getBookingDetail?.customer?.phone : '-'}</h6>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Booking Code</p>
                            <Divider my="sm" />
                            <h6>{getBookingDetail?.booking_code ? getBookingDetail?.booking_code : '-'}</h6>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Car Brand</p>
                            <Divider my="sm" />
                            <h4>{getBookingDetail?.car_brand ? getBookingDetail?.car_brand : '-'}</h4>
                            
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Car Model</p>
                            <Divider my="sm" />
                            <h4>{getBookingDetail?.car_model ? getBookingDetail?.car_model : '-'}</h4>
                        </div>
                    </Grid.Col>

                    <Grid.Col span={2}>
                        <div>
                            <p>Car Year</p>
                            <Divider my="sm" />
                            <h4>{getBookingDetail?.car_year ? getBookingDetail?.car_year : '-'}</h4>
                        </div>
                    </Grid.Col>

                    <Grid.Col span={2}>
                        <div>
                            <p>Fuel Type</p>
                            <Divider my="sm" />
                            <h4>{getBookingDetail?.fuel_type ? getBookingDetail?.fuel_type : '-'}</h4>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Transmission</p>
                            <Divider my="sm" />
                            <h4>{getBookingDetail?.transmission ? getBookingDetail?.transmission : '-'}</h4>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Damage Images</p>
                            <Divider my="sm" />
                            <ul className='mechanicList'>
                                {getBookingDetail?.damage_images?.map(item => (
                                    <li>
                                        <Link to={item.path} target="_blank">
                                            <FiLink
                                                size={20}
                                            />
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Created Date</p>
                            <Divider my="sm" />
                            <h6>{getBookingDetail?.created_at ? moment(getBookingDetail.created_at).format("DD-MM-YYYY") : '-'}</h6>
                        </div> 
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <div>
                            <p>Report Reasons</p>
                            <Divider my="sm" />
                            { getBookingDetail?.report_reason ? <p>{getBookingDetail?.report_reason}</p> : <p>--</p>}
                            
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div>
                            <p>Problem Summary Voice</p>
                            <Divider my="sm" />
                            {/* { getBookingDetail?.summary_voice ? <p>{getBookingDetail?.summary_voice}</p> : <p>--</p>} */}
                            <audio controls>
                                <source src={getBookingDetail?.summary_voice} type="audio/mpeg"/>
                                <track src="my" kind="captions" label="english_captions"/>
                                <track src="./my.mp3" kind="captions" label="spanish_captions"/>
                            </audio>
                            
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div>
                            <p>Problem Summary</p>
                            <Divider my="sm" />
                            { getBookingDetail?.summary_text ? <p>{getBookingDetail?.summary_text}</p> : <p>--</p>}
                            
                        </div>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <div>
                            <p>Job Location</p>
                            <Divider my="sm" />
                            { getBookingDetail?.current_location ? <p>{getBookingDetail?.current_location}</p> : <p>--</p>}
                            
                        </div>
                    </Grid.Col>
                </Grid>
                {/* <Grid>
                    <Grid.Col span={12}>
                        <div>
                            <p>Services</p>
                            <Divider my="sm" />
                            {userDetail?.services?.map(item => (
                                <Grid.Col span={2}>
                                    <Box
                                        sx={(theme) => ({
                                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                            textAlign: 'center',
                                            padding: theme.spacing.xl,
                                            borderRadius: theme.radius.md,
                                            cursor: 'pointer',

                                            '&:hover': {
                                                backgroundColor:
                                                    theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
                                            },
                                        })}
                                    >

                                        <p>{item.name}</p>
                                        <p>
                                            <Image
                                                src={item.image}
                                                alt="Avatar Thumb"
                                                sx={(_) => ({
                                                    height: '60px',
                                                    width: '60px',
                                                    overflow: 'hidden',
                                                })}
                                            />
                                        </p>

                                        <p><Switch
                                            checked={item.is_enabled}
                                            onChange={() => statusUpdate(item)}
                                            onLabel="ON"
                                            offLabel="OFF"
                                            size="md"
                                            color="yellow"
                                        /></p>




                                    </Box>
                                </Grid.Col>
                            ))}
                        </div>
                    </Grid.Col>
                </Grid> */}
            </Card >
        )

    }
    return (<span>--z</span>)


};

export default BookingDetail;
