
import React from 'react'
import { Card } from '@mantine/core';

import ImageUploader from '../../../components/ImageUploader/ImageUploader';

const ImageUploaderCard = () => (
    <Card>
        <ImageUploader />
    </Card>
)

export default ImageUploaderCard
