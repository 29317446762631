import React from 'react';
import { Text, Group, List, Header } from '@mantine/core';
import { Link } from "react-router-dom";

const Footer = () => (
  <div style={{ padding: '0 255px', background: 'white' }}>
    <Text>
      COPYRIGHT © 2024 <span style={{ color: '#FFA540' }}>ARC</span>, All rights Reserved
    </Text>
  </div>
);

export default Footer;

