
import React, { useState, useCallback } from 'react';
import { Modal, Button, TextInput, Textarea, Box, Group, FileInput, Input } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


// API-Service   
import { dataListingService, CreateDataService } from '../../../../service/topupService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { setDataList, setTotalEntry, selectIsPopup, setPopup } from '../../../../redux/topupSlicer';

const FilterMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCreateModal, setCreateModal] = useState(false);
    const token = getToken();


    const form = useForm({
        initialValues: {
            amount: '',
        },
        validate: {
            amount: (value) => (value.length < 1 ? 'Please enter amount' : null),
        },
      });

      const handleCreate = async (values) => {
        const input = {
            amount: values.amount
        }
        const result = await CreateDataService({ token, }, input);
    
        if (result?.data?.status) {
          SuccessToast(result?.data?.message);
          const resultAll = await dataListingService({ filterTerm: '', page: 1, countPerPage: 10, token });
          if (resultAll?.data?.status) {
            dispatch(setDataList(resultAll?.data?.data?.topup_lists));
            dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
            setCreateModal(false);
            form.reset();
          } else {
            ErrorToast(resultAll);
          }
        } 
      };
      const hendleCancel = () => {
        setCreateModal(false);
      };
    

    const openTopup = () => {
        dispatch(setPopup(true))
        // setOpened(true)
    }

    return (
        <>
            {/* <Menu control={<Button variant="outline" color="dark">More Filter Options</Button>}>
                <Menu.Item onClick={hendleActive}>
                    Active
                </Menu.Item>
                <Menu.Item onClick={hendleInActive}>
                    In Active
                </Menu.Item>
            </Menu> */}

            <Button color="yellow" className='' onClick={() => setCreateModal(true)} >Create Topup</Button>

            <Modal opened={isCreateModal} onClose={() => setCreateModal(false)} title="Create Topup" centered>
        <form onSubmit={form.onSubmit((values) => handleCreate(values))}>
          <TextInput type="number" min="1" label="Topup Amount" placeholder="Enter amount here" {...form.getInputProps('amount')} />

          <Group position="center" mt="md">
            <Button color="dark" variant="filled" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button color="yellow" type="submit">Create</Button>
          </Group>
        </form>
      </Modal>
        </>
    )
}

export default FilterMenu