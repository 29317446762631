import { createSlice } from "@reduxjs/toolkit";
 
export const complainSlice = createSlice({
  name: "complain",
  initialState: {
    UserComplains: [],
    UsersTotalEntry: 0 , 
    MechanicContact: [],
    MechanicTotalEntry: 0 , 
  },           
  reducers: {  
    setUserComplaintList: (state, action) => {
      state.UserComplains = action.payload;
    },
    setTotalEntry: (state, action) => {
      state.TotalEntry = action.payload;
  },

  },
});
 
export const {
    setUserComplaintList, 
    setTotalEntry,

} = complainSlice.actions;

export const selectStateUserComplain = (state) => state.complain.UserComplains; 
export const selectTotalEntry = (state) => state.complain.TotalEntry; 

export default complainSlice.reducer;
