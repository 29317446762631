import React, { useState, useEffect } from 'react';
import { Box, Image, Switch } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { Rating } from 'react-simple-star-rating'
import ReactPaginate from 'react-paginate';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

import Loading from '../../../../components/Loading/Loading'; 

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Delete and Update
import DeleteUpdate from './UserDelete';
import UserMessage from './UserMessage';

// API-Service
import { userContactListingService, userContactUpdateStatusService, userContactSearchService, } from '../../../../service/contactService';
  
// Redux-Settings
import { 
  setUserContactList,
  setUserTotalEntry,
  selectStateUserContact,
  selectUserTotalEntry,
} from '../../../../redux/contactSlice';


// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';

  
const ListingTable = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const Listing = useSelector(selectFilteredList);
  const [currentPage, setCurrentPage] = useState(0);
  const Listing = useSelector(selectStateUserContact);
  const totalEntry = useSelector(selectUserTotalEntry);

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (page) => {
    const result = await userContactListingService({ page, countPerPage, token });
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setUserContactList(result?.data?.data?.customer_contact_us_lists));
      dispatch(setUserTotalEntry(result?.data?.data?.pagination?.total));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  };

  const statusUpdate = async (rowRecord) => {
    const statusUpdateresult = await userContactUpdateStatusService({ id: rowRecord.id, token });
    if (statusUpdateresult?.data?.status) {
      SuccessToast(statusUpdateresult.data.message)
      fetchData();
    } else {
      ErrorToast(statusUpdateresult);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData( pageCount);
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [pageCount]);

  const columns = [
    {
      name: 'Name',
      selector: (row) => row?.customer?.name ? row?.customer?.name : '--',
    },
    // {
    //   name: 'Email',
    //   selector: (row) => row?.customer?.email ? row?.customer?.email : '--',
    // },
    {
      name: 'Phone',
      selector: (row) => row?.customer?.phone ? row?.customer?.phone : '--',
    },
    { 
      name: 'Message',
      selector: (row) => <UserMessage tableRecord={row} />,
      sortable: true, 
      minWidth: '120px'
    },

    { 
      name: 'Resolved Status',
      selector: (row) => (
        row.status === 1 ?
        <Switch
          checked={row.status === 1}
          onChange={() => statusUpdate(row)}
          onLabel="Not Resolved"
          offLabel="Resolve"
          size="md"
          color="yellow"
        />
        : <Switch
        checked={row.status === 0}
        onChange={() => statusUpdate(row)}
        onLabel="Resolved"
        offLabel="Not Resolve"
        size="md"
        disabled
        color="yellow"
      />
      ), 
      maxWidth: '150px',
    }, 
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      maxWidth: '300px', 
    },
    { 
      name: 'Actions',
      selector: (row) => <DeleteUpdate tableRecord={row} />,
      sortable: true, 
      minWidth: '120px'
    },
  ];

  
   // ** Function to handle Pagination
   const handlePagination = page => {
    setCurrentPage(page.selected)
}

   // ** Custom Pagination
   const CustomPagination = () => (
    <ReactPaginate
        nextLabel={<div className='d-inline-flex align-items-center bg-white p-50 border border-2 rounded p-1'>
            <span className='fs-14 fw-bold me-2 text-dark '>Next</span>
            <AiOutlineArrowRight className='text-secondary' size={18} />
        </div>}
        breakLabel='...'
        previousLabel={<div className='d-inline-flex align-items-center bg-white p-50  border border-2 rounded p-1'>
            <AiOutlineArrowLeft className='text-secondary me-2' size={18} />
            <span className='fs-14 fw-bold text-dark' >Previous</span>
        </div>}
        pageRangeDisplayed={3}
        forcePage={currentPage}
        marginPagesDisplayed={3}
        activeClassName='active bg-light border rounded px-2 d-flex align-items-center text-dark border-3'
        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
        breakClassName='page-item me-3 d-flex align-items-center'
        nextClassName='page-item next-item flex-grow-1 text-end'
        previousClassName='page-item prev-item flex-grow-1 me-3'
        disabledLinkClassName='btn disabled p-0 border-0'
        pageCount={Math.ceil(totalEntry / 10) || 1}
        onPageChange={page => handlePagination(page)}
        containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-2 ps-1'
    />
)


  if (loading) {
    return (
      <Box
      sx={() => ({
        margin: '0 auto',
        minHeight: 440,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      })}
    >
      <span className="visually-hidden"><Loading /></span>
    </Box>
    );
  }
  return (
    <Box
    >
      <Box className="rui-filemanager-content">
        <Box className="table-responsive-lg mb-20 inner-content-fix">
          <DataTable
            className="rui-datatable rui-filemanager-table table mb-20 mt-10"
            columns={columns}
            data={Listing}
            key={Listing?.id}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalEntry}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={(page) => setPageCount(page)}
            paginationComponent={CustomPagination}
            paginationDefaultPage={currentPage + 1} 
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;
