import React from 'react';

import { Card, Image, Text, Badge, Button, Group, Box, useMantineTheme } from '@mantine/core';

import Data from '../../data/db.json'

const CardLayout = () => {
  const theme = useMantineTheme();
  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];
    const cardData = Data?.cards
  return (
    <Group sx={ _ => ({
        margin: '50px auto',
        '@media (max-width: 910px)': {
            justifyContent: 'center'
        }
    })}>
            {cardData?.map((item) => 
                <Box 
                    key={item?.id} 
                    sx={_=>({
                        width: '330px',
                        margin: '0',
                        '@media (max-width: 960px)': {
                            width: '320px'
                        },
                        '@media (max-width: 910px)': {
                            width: '80%'
                        },
                        '@media (max-width: 480px)': {
                            width: '100%'
                        }
                    })}
                >
                    <Card shadow="sm" p="lg">
                        { item?.img && 
                            <Card.Section>
                                <Image src={item?.img} height={160} alt="Norway" />
                            </Card.Section>
                        }
                        <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                            <Text weight={500}>{item?.title}</Text>
                            <Badge color="pink" variant="light">
                                {item?.badge}
                            </Badge>
                        </Group>
                        { item?.notes && 
                            <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5 }}>
                                {item?.notes}
                            </Text>
                        }
                        <Button variant="light" color="blue" fullWidth style={{ marginTop: 14 }}>
                            Book classic tour now
                        </Button>
                    </Card>
                </Box>
            )}
    </Group>

  );
}

export default CardLayout;