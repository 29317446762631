
import React from 'react'
import { Group } from '@mantine/core';

// Core Elements
import ButtonElement from '../../../components/MicroElements/ButtonElement';
import CheckboxElement from '../../../components/MicroElements/CheckboxElement';
import ActionIconElement from '../../../components/MicroElements/ActionIconElement';
import ChipsElement from '../../../components/MicroElements/ChipsElement';
import ColorInputElement from '../../../components/MicroElements/ColorInputElement';
import InputElement from '../../../components/MicroElements/InputElement';
import SelectElement from '../../../components/MicroElements/SelectElement';
import MultiSelectElement from '../../../components/MicroElements/MultiSelectElement';
import RadioElement from '../../../components/MicroElements/RadioElement';
import SliderElement from '../../../components/MicroElements/SliderElement';
import SwitchElement from '../../../components/MicroElements/SwitchElement';
import TabElement from '../../../components/MicroElements/TabElement';
import AccordionElement from '../../../components/MicroElements/AccordionElement';


const Elements = () =>(
    <Group>
        <ButtonElement/>
        <CheckboxElement/>
        <ActionIconElement/>
        <ChipsElement />
        <ColorInputElement />
        <InputElement />
        <SelectElement />
        <MultiSelectElement />
        <RadioElement />
        <SliderElement />
        <SwitchElement />
        <TabElement />
        <AccordionElement />
    </Group>
)

export default Elements
