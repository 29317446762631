import React, { useState, useEffect, useCallback } from 'react';
import { TextInput, Alert, Textarea, Select, MultiSelect, Card, Grid, Calendar, Box, Button, Modal, Group, Notification } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { useDispatch, useSelector } from 'react-redux';
import { RichTextEditor } from '@mantine/rte';
import { FiBell } from "react-icons/fi";

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { PlanListingServiceCustomer, CreateDataServiceCustomer } from '../../../../service/planService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { setFreelancerPlansList, selectStateFreelancerPlans, } from '../../../../redux/planSlice';

const Create = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getCharge = useSelector(selectStateFreelancerPlans);
  // const [subscriptionCharge, setMyValue] = useState('');
  const navigate = useNavigate();
  const token = getToken();
   // console.log('getCharge', getCharge)
  useEffect(() => {
    // if (getCharge?.length) {
    //   setMyValue(getCharge[5]?.value)
    // }
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);
  // console.log('charge', subscriptionCharge)

  const form = useForm({
    initialValues: {
      amount: '',
      month: '',
      renewal_value: ''
    },
    validate: {
      amount: (value) => (value.length < 1 ? 'Please enter amount' : null),
      month: (value) => (value.length < 1 ? 'Please enter month' : null),
    },

  });

  const handleCreate = async (values) => {
    // console.log('input', values)
    const strings = {
      data: [
        {month: values.month, name: "freelancer_subscription_amount", value : values.amount, renewal_value: values.renewal_value }
      ]
    }
    const result = await CreateDataServiceCustomer({ token, }, strings);

    if (result?.data?.status) {
      SuccessToast('Freelance subscription have been changed successfully')
      const resultAll = await PlanListingServiceCustomer({ page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        form.reset();
        // console.log('res',resultAll?.data?.data?.mechanic_variables_list[5]?.value)
        dispatch(setFreelancerPlansList(resultAll?.data?.data?.mechanic_variables_list));
        setLoading(false);
        form.reset();
      } else {
        setLoading(false);
        ErrorToast(resultAll.data?.message);

      }
    }
  };
  const hendleCancel = () => {
    navigate('/admin/plans');
  };


  const handleCharge = (e) => {
    // setMyValue((prevState) => ({
    //   ...prevState,
    //   name: "freelancer_subscription_amount",
    //   month: '2',
    //   value: e.target.value
    // }));
  };

  const fetchData = async () => {
    const result = await PlanListingServiceCustomer({ token });
    if (result?.data?.status) {
      dispatch(setFreelancerPlansList(result?.data?.data?.mechanic_variables_list));
    }
  };

  useEffect(() => {
    fetchData();
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);
  console.log('getCharge', getCharge)
  return (
    <Card>
      <div className='bg-white'>
      <Grid>
        <Grid.Col span={4}>
          <Alert icon={<FiBell size={16} />} title="Freelancer Subscription Charge" color="green"> <h2>{`RM ${getCharge[5]?.value}`}</h2></Alert>
        </Grid.Col>
        <Grid.Col span={4}>
          <Alert icon={<FiBell size={16} />} title="Freelancer Renewal Charge" color="green"> <h2>{`RM ${getCharge[5]?.renewal_value}`}</h2></Alert>
        </Grid.Col>
        <Grid.Col span={4}>
          <Alert icon={<FiBell size={16} />} title="Freelancer Subscription Month" color="green"> <h2>{`Month ${getCharge[5]?.month}`}</h2></Alert>
        </Grid.Col>
        
      </Grid>
      <form onSubmit={form.onSubmit((values) => handleCreate(values))}>
        <Grid>
          <Grid.Col span={4}> 
            {/* <TextInput type="number" label="Charge" placeholder="Enter amount to change charge" onClick={handleCharge} id="rte" defaultValue={subscriptionCharge} /> */}
            <TextInput type="number" label="Charge" placeholder="Enter amount to change charge" {...form.getInputProps('amount')}/>
          </Grid.Col>
          <Grid.Col span={4}> 
            <TextInput type="number" label="Renewal Charge" placeholder="Enter here" {...form.getInputProps('renewal_value')}/>
          </Grid.Col>
          <Grid.Col span={4}> 
            <TextInput type="number" label="Month" placeholder="Enter month" {...form.getInputProps('month')}/>
          </Grid.Col>
         

          <Grid.Col span={12}>
            <Group position="left" mt="md">
              <Button color="dark" onClick={hendleCancel}>
                Cancel
              </Button>
              <Button color="yellow" type="submit">Save</Button>
            </Group>
          </Grid.Col>
        </Grid>
      </form>
      </div>
    </Card >
    
  );
};

export default Create;
