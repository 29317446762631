import React, { useState, useEffect, useCallback } from 'react';
import { TextInput, Text, FileButton, Button, Flex, Group, List, Image, Textarea, MultiSelect, Card, Grid, Calendar, Box, Modal, Notification } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiFileText, FiX } from "react-icons/fi";
import myUpload from 'vue-image-crop-upload';

// ** Third Party Components
import Select from 'react-select'

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { getAllListService, getSingleDataService, mechanicListService, mechanicUpdateService } from '../../../../service/mechanic';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast, CatchErrorToast } from '../../../../utils/utilities';

// Redux-Settings
import {
    setMechanicList,
    setNewServices,
    setTotalEntry,
    selectStateSingleData,
    setSingleData,
    selectServicesList,
} from '../../../../redux/mechanicSlice';

const Update = ({ tableRecord }) => {
    const [startDate, setStartDate] = useState(new Date());

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [getImages, setSSMFiles] = useState([])
    const [showProfileImage, setProfileImage] = useState();
    const [ssm_documents, setSsmDocuments] = useState();
    const [services, setServices] = useState([]);
    const [bookingServices, setBookingServices] = useState([]);
    const token = getToken();
    const navigate = useNavigate();
    const [formValue, setFromValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const { id } = useParams()
    if (ssm_documents) {
        console.log('setSsmDocuments', ssm_documents)
    }

    const form = useForm({
        initialValues: {
            // company_name email phone ssm ic_number bookingServices subscription_start_date subscription_end_date experience_summary
            company_name: '',
            legal_name: null,
            // email: '',
            phone: '',
            // ssm: '',
            ic_number: '',
            bookingServices: '',
            subscription_start_date: '',
            subscription_end_date: '',
            experience_summary: '',
        },
        // validate: {
        //     code: (value) => (value.length < 2 ? 'Please enter name' : null),
        //     couponType: (value) => (value.length < 2 ? 'Please enter type' : null),
        //     discount: (value) => (value.length < 2 ? 'Please enter discount' : null),
        //     max_discount: (value) => (value.length < 2 ? 'Please enter max mount' : null),
        //     min_purchase: (value) => (value.length < 2 ? 'Please enter min purchase amount' : null),
        //     limit_per_user: (value) => (value.length < 1 ? 'Please enter limit per user' : null),
        //     user_type: (value) => (value.length < 2 ? 'Please enter user type' : null),
        //     expire_coupon: (value) => (value.length < 2 ? 'Please select expire date' : null),
        // },
    });


    const fetchData = async () => {
        const result = await getAllListService({ token });
        if (result?.data?.status) {
            const tempValue = result?.data?.data?.mechanic_services_list?.map((item) => ({ value: item.id, label: item.name }));
            dispatch(setBookingServices(tempValue));
        }
    };

    const getSingleData = async () => {
        const result = await getSingleDataService({ id, token });
        if (result?.data?.status) {
            setLoading(false);
            setFromValue(result?.data?.data);
            if (result?.data?.data?.services.length > 0) {
                setServices(result?.data?.data?.services.map((item, key) => ({ value: item.id, label: item.name })))
            } else {
                console.log('Azad')
            }
            if (result?.data?.data?.ssm_documents.length > 0) {
                setSsmDocuments(result?.data?.data?.ssm_documents.map((item, key) => (item.path)))
            }
        } else {
            setLoading(false);
            ErrorToast(result);
        }
    };
    useEffect(() => {
        getSingleData();
        fetchData();
        // if (formValue?.ssm_documents) {
        //     setImages(formValue?.ssm_documents?.map((item) => (item.name)));
        // } else {
        //     setImages(formValue?.ic_images?.map((item) => (item.name)));
        // }

        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    const handleCompanyNameChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            company_name: e.target.value,
        }));
    };
    const handleLegalNameChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            legal_name: e.target.value,
        }));
    };
    const handleServicesChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            services: e,
        }));
    };

    // const handleEmailChange = (e) => {
    //     setInputError(false);
    //     setFromValue((prevState) => ({
    //         ...prevState,
    //         email: e.target.value,
    //     }));
    // };

    const handlePhoneChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            phone: e.target.value,
        }));
    };

    const handleSMChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            ssm: e.target.value,
        }));
    };

    const handleICChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            ic_number: e.target.value,
        }));
    };

    const handleDiscriptionChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            experience_summary: e.target.value,
        }));
    };
    const handleUpdate = async (e) => {
        // console.log('input', file)
        // e.preventDefault();
        const formData = new FormData()
        if (showProfileImage) {
            console.log('Profile Image:', showProfileImage);
            formData.append('profile_pic', showProfileImage);
        }
    
        // ... (rest of the formData appending)
    
        console.log('FormData:', formData);
        if (getImages) {
            console.log('images', getImages)
            getImages.map((element, index) => (
                formValue?.mechanic_type === 'FREELANCER' ? formData.append(`ic_images[${index}]`, element) : formData.append(`ssm_documents[${index}]`, element)
            ))
        }
        if (services.length) {
            services.map((element, index) => (
                formData.append(`service_ids[${index}]`, element.value)
            ))
        }
        if (formValue.mechanic_type === 'FREELANCER') {
            formData.append('legal_name', formValue?.legal_name)
            formData.append('ic_number', formValue?.ic_number)
            formData.append('experience_summary', formValue?.experience_summary)
        } else {
            formData.append('company_name', formValue?.company_name)
            formData.append('ssm', formValue?.ssm)
        }
        formData.append('id', formValue?.id)
        formData.append('start_date', formValue?.start_date)
        formData.append('end_date', formValue?.end_date)
        // formData.append('email', 'azad@segwitz.com')
        formData.append('phone', formValue?.phone)
        formData.append('subscription_start_date', formValue?.subscription_start_date)
        formData.append('subscription_end_date', formValue?.subscription_end_date)
        formData.append('_method', 'PUT')
        const result = await mechanicUpdateService({ token, id }, formData);

        if (result?.data?.status) {
            const resultAll = await mechanicListService({
                filterTerm: '',
                page: 1,
                countPerPage: 10,
                token
            })
                .catch((error) => {
                    console.log('MyError', error.response.data.message)
                    CatchErrorToast(error);
                });
            if (resultAll?.data?.status) {
                form.reset();
                dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
                setLoading(false);
                SuccessToast(result?.data?.message)
                navigate('/admin/mechanics');
                form.reset();
            } else {
                setLoading(false);
                ErrorToast(resultAll);

            }
        }
    };

    const changeHandler = (event) => {
        const selectedFile = event.target.files[0];
    
        if (selectedFile) {
            console.log('Selected File Type:', selectedFile.type);
    
            if (selectedFile.type.startsWith('image') && ['image/jpeg', 'image/png'].includes(selectedFile.type)) {
                setProfileImage(selectedFile);  // Update to store the File object
            } else {
                console.error('Invalid file type. Please select a valid image file (jpg, jpeg, png).');
                // Display an error message to the user
            }
        }
    };
    
    

    // const selectImages = (event) => {
    //     console.log('b', event.target.files)
    //     setFiles(event.target.files)
    // };

    const selectImages = async (event) => {
        // const myData = event.target.files
        const myData = [...event.target.files]
        setSSMFiles(myData)
        // if (getImages.length > 0) {
        //     const newImageUrls = [];
        //     getImages.forEach((item) => {
        //         newImageUrls.push(URL.createObjectURL(item))
        //     })
        //     setImageURLs(newImageUrls);
        // }
    }
    const renderFilePreview = file => {
        if (file.type.startsWith('image')) {
            return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28' />
        }
        return <FiFileText size='28' />
        // else {
        //     <FiFileText size='28' />
        // }
    }

    const handleRemoveFile = file => {
        // const uploadedFiles = files
        const uploadedFiles = getImages
        const filtered = uploadedFiles.filter(i => i.name !== file.name)
        setSSMFiles([...filtered])
    }

    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        }
        return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        // else {
        //     `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        // }
    }
    let viewImages = ''
    if (getImages.length > 0) {
        viewImages = getImages.map((file, index) => (
            <Grid.Col span={12}>
                <List.Item key={`${file.name}`} className='d-flex align-items-center justify-content-between'>
                    <div className='ssm-files'>
                        <div>{renderFilePreview(file)}</div>
                        <div className='file-name mb-0'>{file.name}</div>
                        <div className='file-size mb-0'>{renderFileSize(file.size)}</div>
                        <div><Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
                            <FiX size={14} />
                        </Button></div>
                    </div>

                </List.Item>
            </Grid.Col>
        ))
    }

    const handleRemoveAllFiles = () => {
        setSSMFiles([])
    }
    const handleRemoveServerFile = file => {
        console.log('remove 1', file)
        // const uploadedFiles = files
        const uploadedFiles = ssm_documents
        ssm_documents.filter(item => item !== file)
        setSSMFiles(ssm_documents)
    }

    let previewImages = ''
    if (ssm_documents) {
        previewImages = ssm_documents.map((file) => (
            <Grid.Col span={12}>
                <List.Item key={`${file}`} className='d-flex align-items-center justify-content-between'>
                    <div className='ssm-files'>
                        <img className='rounded' alt='Images' src={file} height='28' width='28' />
                        {/* <div><Button color='danger' outline size='sm' className='btn-icon' 
                        onClick={() => handleRemoveServerFile(file)}>
                            <FiX size={14} />
                        </Button></div> */}
                    </div>

                </List.Item>
            </Grid.Col>
        ))
    }

    const handleCancel = () => {
        setInputError(false);
        form.reset();
        navigate('/admin/mechanics');
    };

    if (loading) {
        return (
            <Box>
                <Loading />
            </Box>
        );
    }


    return (
        <Box>
            <Card>
                <Grid>
                    <Grid.Col span={12}><h3>Update Profile</h3></Grid.Col>
                </Grid>
                <form onSubmit={form.onSubmit((values) => handleUpdate(values))}>
                    <Grid>
                        {formValue.mechanic_type === 'MECHANIC' ? <Grid.Col span={6}>
                            <TextInput required label="Company Name" placeholder="Enter here" value={formValue?.company_name || ""} onChange={handleCompanyNameChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter coupon code</Text>}
                        </Grid.Col> : <Grid.Col span={6}>
                            <TextInput required label="Legal Name" placeholder="Enter here" value={formValue?.legal_name || ""} onChange={handleLegalNameChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter coupon code</Text>}
                        </Grid.Col>
                        }

                        {/* <Grid.Col span={6}>
                            <TextInput required type="email" label="Email" placeholder="Enter here" value={formValue?.email || ""} onChange={handleEmailChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter email</Text>}
                        </Grid.Col> */}
                        <Grid.Col span={6}>
                            <TextInput required type="number" label="Phone" placeholder="Enter here" value={formValue?.phone || ""} onChange={handlePhoneChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter phone</Text>}
                        </Grid.Col>
                        {formValue.mechanic_type === 'MECHANIC' ? <Grid.Col span={6}>
                            <TextInput required label="SSM Number" placeholder="Enter here" value={formValue?.ssm || ""} onChange={handleSMChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter SSM Number</Text>}
                        </Grid.Col> : <Grid.Col span={6}>
                            <TextInput required label="IC Number" placeholder="Enter here" value={formValue?.ic_number || ""} onChange={handleICChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter IC Number</Text>}
                        </Grid.Col>
                        }
                        <Grid.Col span={6}>
                            <Text for="services">Services</Text>
                            <Select
                                isMulti
                                name="Services"
                                placeholder='Services'
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={services}
                                options={bookingServices}
                                onChange={setServices}
                            />
                            {/* {services.length ? <Select
                                isMulti
                                name="Services"
                                placeholder='Services'
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={services}
                                options={bookingServices}
                                onChange={setServices}
                            />
                                : <Select
                                    isMulti
                                    name="Services"
                                    placeholder='Services'
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    // defaultValue={services}
                                    options={bookingServices}
                                    onChange={setServices}
                                />
                            } */}
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select Services </Text>}
                        </Grid.Col>


                        <Grid.Col span={6}>
                            <TextInput disabled required type="date" label="Subscription Start Date" placeholder="Pick date" withAsterisk value={formValue?.subscription_start_date || ""} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select start date </Text>}
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput disabled required type="date" label="Subscription End Date" placeholder="Pick date" withAsterisk value={formValue?.subscription_end_date || ""} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please select expiry date  </Text>}
                        </Grid.Col>
                        {formValue?.mechanic_type === 'FREELANCER' ? <Grid.Col span={12}>
                            <Textarea required label="Experience Summary" placeholder="Enter here" row="6" value={formValue?.experience_summary || ""} onChange={handleDiscriptionChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter description </Text>}
                        </Grid.Col> : ''}
                        <Grid.Col span={6}>
                            <div style={{ marginTop: 0 }}>
                                <TextInput label="Select Photo" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
                            </div>

                        </Grid.Col>
                        <Grid.Col span={6}>
                            {
                                showProfileImage ?
                                    <div style={{ marginTop: 15 }}>
                                        <Image src={showProfileImage}
                                            alt="Profile Picture"
                                            sx={(_) => ({
                                                height: '80px',
                                                width: '80px',
                                                overflow: 'hidden',
                                            })} />
                                    </div>
                                    : <div style={{ marginTop: 15 }}>

                                        <Image src={formValue?.profile_pic}
                                            alt="Profile Picture"
                                            sx={(_) => ({
                                                height: '80px',
                                                width: '80px',
                                                overflow: 'hidden',
                                            })} />
                                    </div>
                            }
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <div className='mb-1'>
                                <div style={{ marginTop: 0 }}>
                                    <TextInput label="Upload IC/SSM Images" type="file" name="file" accept="image/png, image/jpg, image/jpeg" multiple onChange={selectImages} />
                                </div>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <div>
                                <h4>Selected images</h4>
                                {getImages.length ? (
                                    <List className='my-2'>{viewImages}</List>
                                ) : null}
                                {previewImages.length ? (
                                    <List className='my-2'>{previewImages}</List>
                                ) : null}
                            </div>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Group position="left" mt="md">
                                <Button color="dark" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button color="yellow" type="submit">Save</Button>
                            </Group>
                        </Grid.Col>

                    </Grid>
                </form>


            </Card>
        </Box >
    );
};

export default Update;
