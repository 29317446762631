import { createSlice } from "@reduxjs/toolkit";

export const postSlice = createSlice({
  name: "user ",
  initialState: {
    postList: null, 
    filteredPostList: null
  },
  reducers: { 
    setPostList: (state, action) => {
      state.postList = action.payload;
    },  
    addPost: (state, action) => {
      state.postList.push(action.payload)
      state.filteredPostList.push(action.payload)
    },
    setFilteredPostList: (state, action) => {
        state.filteredPostList = action.payload;
    },
    updatePost: (state, action) => {
      const index = state.filteredPostList.findIndex((item) => item.id === action.payload.id);
      state.filteredPostList[index] = action.payload;
    },
    deletePost: (state, action) => {
      state.filteredPostList = state.filteredPostList?.filter(item => item.id !== action.payload);
    }
  },
});

export const {
    setPostList,
    addPost,
    updatePost,
    deletePost,
    setFilteredPostList
} = postSlice.actions;

export const selectPostList = (state) => state.posts.postList;
export const selectFilteredPostList = (state) => state.posts.filteredPostList;

export default postSlice.reducer;
