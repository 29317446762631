
import React from 'react'
import { Box, Image, Title, Text, PasswordInput } from '@mantine/core';

import LoginForm from '../../../components/Forms/Login'

// Images
import Logo from "../../../assets/images/Icon.png"
import loginScreen02 from "../../../assets/images/loginScreen01.png"

const LoginPageV3 = () => (
    <Box sx={_ => ({
            display: 'flex',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            position: 'relative',
            overflow: 'hidden'
        })}
    >   
        <Image 
            height='100vh' 
            width='100vw'
            sx={_ => ({
              position: 'absolute',
              top: 0,
              left: 0
            })}
            src={loginScreen02} 
            alt="Screen" 
        />

        <Box sx={ _ => ({
                margin: '0 auto',
                display: 'flex',
                '@media (max-width: 912px)': {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    margin: '0 auto'
                }
            })}>
            <Box sx={(theme) => ({
                width: 440,
                minHeight: '440px',
                margin: 'auto',
                display: 'flex',
                alignItems: "center",
                justifyContent: 'center',
                borderRadius: '5px 0 0 5px',
                position: "relative",
                padding: "15px",
                flexDirection: 'column',
                backgroundColor: theme.fn.darken('#fda53f', 0.05),
                '@media (max-width: 912px)': {
                    display: 'none',
                }
            })}>
                <Image
                    style={{
                        height: '220px',
                        width: '220px',
                        margin: '20px 0',
                    }}
                    src={Logo}
                    alt="Dashboard Logo"
                />
                <Title 
                    sx={(theme) => ({
                        margin: '14px auto',
                        textAlign: 'center',
                        color: theme.colors.gray[0]
                    })}
                    order={3}
                >
                    Welcome To The Panel
                </Title>
                
            </Box>
            <Box sx={ _ => ({
                width: 440,
                minHeight: '440px',
                margin: 'auto',
                display: 'flex',
                alignItems: "center",
                backgroundColor: '#fff',
                position: 'relative',
                flexDirection: 'row',
                borderRadius: '0 5px 5px 0',
                '@media (max-width: 912px)': {
                    width: '80%',
                    flexDirection: 'column',
                },
                '@media (max-width: 512px)': {
                    width: '90%',
                }
            })}>
                <Image
                    sx={ _ => ({
                        height: '110px',
                        width: '110px',
                        margin: '20px 0',
                        '@media (min-width: 912px)': {
                            display: 'none',
                        }
                    })}
                    src={Logo}
                    alt="Dashboard Logo"
                />
                <LoginForm shadow={false} />
            </Box>
        </Box>
    </Box>
)

export default LoginPageV3
