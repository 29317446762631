import { createSlice } from "@reduxjs/toolkit";
 
export const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState: {   
    UserWithdrawals: [],
    UsersTotalEntry: 0 , 
    MechanicWithdrawals: [],
    MechanicTotalEntry: 0 , 
  },       
  reducers: {  
    setUserWithdrawalsList: (state, action) => {
      state.UserWithdrawals = action.payload;
    },
    setMechanicWithdrawalsList: (state, action) => {
      state.MechanicWithdrawals = action.payload;
    },
    setMechanicTotalEntry: (state, action) => {
        state.MechanicTotalEntry = action.payload;
    },
    setUserTotalEntry: (state, action) => {
      state.UsersTotalEntry = action.payload;
  },

  },
});  
 
export const {
    setUserWithdrawalsList, 
    setMechanicWithdrawalsList,
    setUserTotalEntry,
    setMechanicTotalEntry,

} = withdrawalSlice.actions;

export const selectStateUserWithdrawals = (state) => state.withdrawals.UserWithdrawals; 
export const selectStateMechanicWithdrawals = (state) => state.withdrawals.MechanicWithdrawals;
export const selectUserTotalEntry = (state) => state.withdrawals.UsersTotalEntry; 
export const selectMechanicTotalEntry = (state) => state.withdrawals.MechanicTotalEntry; 

export default withdrawalSlice.reducer;
