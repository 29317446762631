import React, { useState, useEffect, Fragment } from 'react'
 
import { Row, Col, Card, CardTitle, CardText, CardBody, Form, Label, Input, Button, FormFeedback, Toast, ToastBody, ToastHeader, Spinner } from 'reactstrap'
 
// Redux and Services Setting
import Logo from '../../../../assets/images/logo.png'
import SucessLogo from '../../../../assets/images/success.png'

const SucessPage = () => (
    <>
      <Row>
        <Col>
          <div className="p-3">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img src={Logo} alt="logo" width="90px" height="90px" />
              <h2 className="mt-3">Arc App</h2>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div className="bg-white p-3 d-flex flex-column col-md-5 col-12 align-items-center justify-content-center shadow">
                <img src={SucessLogo} alt="logo" />
                <h5 className="fw-bold text-green">Successful!</h5>
                <p className="mb-3">You have successfully deleted your Arc account.</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
  
  export default SucessPage;