import React, { useEffect, useState } from 'react';
import { Input } from '@mantine/core';
import { Search } from 'tabler-icons-react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getToken, removeUserSession } from '../../../../utils/AuthService';

// API-Service
import { userSearchService } from '../../../../service/user';

// Redux-Settings
import { setUserList } from '../../../../redux/userSlice';

const SearchForm = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState();


    const token = getToken();
    const navigate = useNavigate();

    const [pageCount, setPageCount] = useState(1);
    const countPerPage = 10;

    const fetchData = async (abc) => {
        const result = await userSearchService({ search: abc, countPerPage, token });
        if (result?.data?.status) {
            const responseData = result?.data?.data?.customers
            setLoading(false);
            dispatch(setUserList(responseData));
            console.log("Search results:", responseData);
        }
    };

    const inputHandler = (e) => {
        const inputValue = e.target.value.toLowerCase();
        setSearch(inputValue);
    
        // Fetch the data with the current input value
        fetchData(inputValue);
      };
      
    return (
        <Input
            onChange={inputHandler}
            icon={<Search size={16} />}
            placeholder="Search By User Name"
            style={{ minWidth: '300px' }}
            sx={() => ({
                '@media (max-width: 912px)': {
                    marginBottom: '30px'
                }
            })}
        />
    )
}

export default SearchForm;