import React from 'react';
import { Card,  Text, Badge, Title , List, Button, Group, useMantineTheme } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';

import Data from "../../data/db.json"

const Agreement = () => {
    const theme = useMantineTheme();

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];
    const agreementData = Data?.agreement;
    return(
        <div style={{ width: '100%', margin: '50px auto' }}>
            <Card shadow="sm" p="lg">
                <Badge sx={{ margin: '10px 0' }} color="gray" variant="light">
                    Agrement
                </Badge>
                <Title style={{marginBottom: '40px'}} order={1}>{agreementData?.title}</Title>
                <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5, margin: '0 0 30px 0'}}>
                    {agreementData?.text01}
                </Text>
                <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5, margin: '0 0 30px 0' }}>
                    {agreementData?.text02}
                </Text>
                <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5, margin: '0 0 30px 0' }}>
                    {agreementData?.text03}
                </Text>
                <List 
                    spacing="xs"
                    size="sm"
                    center
                    icon={
                        <CircleCheck style={{color: '#8c939b'}} size={22} />
                    }
                    style={{margin: '50px auto'}}
                >
                    {agreementData?.terms.map(item => (<List.Item key={item}>{item}</List.Item>))}
                </List>
                <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5, margin: '0 0 30px 0'}}>
                    {agreementData?.text04}
                </Text>
                <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5, margin: '0 0 30px 0' }}>
                    {agreementData?.text05}
                </Text>
                <Group style={{margin: '50px auto'}}>
                    <Button variant="outline" size="md">Not Right Now</Button>
                    <Button size="md">I Agree</Button>
                </Group>
            </Card>
        </div>
    )
}


export default Agreement;