import { createSlice } from "@reduxjs/toolkit";
 
export const aboutSlice = createSlice({
  name: "string",
  initialState: {
    dataListing: [],
    mechanicString: [],
    singleData: {},
  },     
  reducers: {   
    setDataList: (state, action) => {
      state.dataListing = action.payload;
    },
    setSingleData: (state, action) => {
      state.singleData = action.payload;
    },
    setDataListMechanic: (state, action) => {
      state.mechanicString = action.payload;
    },

  },
});

export const {
    setDataList, 
    setSingleData,
    setDataListMechanic,
} = aboutSlice.actions;

export const selectStateData = (state) => state.about.dataListing; 
export const selectStateMechanicData = (state) => state.about.mechanicString; 
export const selectStateSingleData = (state) => state.about.singleData; 


export default aboutSlice.reducer;
