import React, { useState, useEffect } from 'react';
import { Menu, Button, Textarea, Modal, Group, Box, TextInput, ActionIcon, Grid, Col, Card, ScrollArea } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash, FiEye, FiMoreVertical,  } from "react-icons/fi";

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service
import { jobComplainList, jobComplainDelete } from '../../../../service/complainService';

// Redux-Settings
import { setUserComplaintList, setTotalEntry } from '../../../../redux/complainSlice';

const DeleteUpdate = ({ tableRecord }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);

  const [formValue, setFromValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  // All Record-update Controller
  const hendleUpdateModal = () => {
    setIsUpdateModal(true);
  };
  
  useEffect(() => {
    setFromValue(tableRecord);
  }, [tableRecord]);
  console.log('Table record', tableRecord)


  const handleTitleChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const handleDescriptionChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };

  const handleWebLinkChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      webLink: e.target.value,
    }));
  };

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
    setIsUpdateModal(false);
  };

  const hendleDelete = async () => {
    const result = await jobComplainDelete({
      id: tableRecord?.id,
      token,
    });
    setIsDeleteModal(false);
    if (result?.data?.success) {
      const resultAll = await jobComplainList({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setUserComplaintList(resultAll?.data?.data?.mechanics));
        dispatch(setTotalEntry(result?.data?.data?.mechanics.length));
        SuccessToast(resultAll.data.message)
        navigate('/admin/mechanic');
      } else {
        ErrorToast(resultAll);
      }
    }
    // setIsDeleteModal(false)
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();
  //   const result = await mechanicUpdateService({
  //     id: formValue?.id,
  //     name: formValue?.name,
  //     image: formValue?.image,
  //     token,
  //   });

  //   if (result?.data?.success) {
  //     const resultAll = await jobComplainList({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
  //     if (resultAll?.data?.success) {
  //       dispatch(setUserComplaintList(resultAll?.data?.data?.list));
  //       dispatch(setTotalEntry(result?.data?.data?.count));
  //     } else {
  //       ErrorToast(resultAll);
  //       if(
  //         result?.data?.errorMessage === "jwt expired" || 
  //         result?.data?.errorMessage === "invalid token" || 
  //         result?.data?.errorMessage === "invalid auth"
  //       ){
  //           removeUserSession();
  //           navigate('/home');
  //       }
  //     }
  //   } else {
  //     ErrorToast(result);
  //     if(
  //       result?.data?.errorMessage === "jwt expired" || 
  //       result?.data?.errorMessage === "invalid token" || 
  //       result?.data?.errorMessage === "invalid auth"
  //     ){
  //         removeUserSession();
  //         navigate('/home');
  //     }
  //   }
  //   setIsUpdateModal(false);
  // };

  return (
    <Box>
        <Button
          onClick={hendleDeleteModal}
        >
          View
        </Button>
        <Modal
        opened={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        title="Description"
        size="65%" 
        centered
        scrollAreaComponent={ScrollArea.Autosize}
        style={{maxHeight: '600px' }}
      >
        <Grid>
          <Card>
            <Col style={{background: '#EFF4FF', padding: '12px'}}>
            {tableRecord?.description || '--'}
            </Col>
          </Card>
        </Grid>
        
          
        
      </Modal>
      {/* <Modal opened={isUpdateModal} onClose={() => setIsUpdateModal(false)} title="Update" centered>
        <form onSubmit={(values) => handleUpdate(values)}>
          <TextInput 
          required 
          label="Title" 
          value={formValue?.title || ''} 
          onChange={handleTitleChange} />
        
          <Textarea
            minRows={7}
            style={{ marginTop: 15 }}
            required
            label="Description"
            value={formValue?.description || ''} 
            onChange={handleDescriptionChange}
          />

          <TextInput
            required
            style={{ marginTop: 15 }}
            label="Link"
            value={formValue?.webLink || ''}
            onChange={handleWebLinkChange}
          />
          <Group position="center" mt="md">
            <Button variant="outline" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </Group>
        </form>
      </Modal> */}
    </Box>
  );
};

export default DeleteUpdate;
