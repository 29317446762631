import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const userWithdrawalListingService = ({ page, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/withdraw/customer/list?paginate=1&page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },  
  });
export const userWithdrawalSearchService = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/withdraw/customer/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  
export const userWithdrawalUpdateStatusService = ({ id, token, is_approved }) =>
  axios.post(
    `${baseUrl}/admin/withdraw/customer/status/${id}`,
    {is_approved},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const userWithdrawalDataRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/withdraw/customer/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const mechanicWithdrawalListingService = ({ page, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/withdraw/mechanic/list?paginate=1&page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
export const mechanicWithdrawalSearchService = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/withdraw/mechanic/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const mechanicWithdrawalUpdateStatusService = ({ id, token, is_approved }) =>
  axios.post(
    `${baseUrl}/admin/withdraw/mechanic/status/${id}`,
    {is_approved},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const mechanicWithdrawalDataRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/withdraw/mechanic/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  // Download User Invoice File
  export const downloadFile = ({ id, token }) =>
    axios.get(
      `${baseUrl}/admin/withdraw/customer/single/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }
    );

    // Download Mechanic Invoice File 
    export const downloadMechFile = ({ id, token }) =>
      axios.get(
        `${baseUrl}/admin/withdraw/mechanic/single/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        }
      );