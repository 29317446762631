import React, { useState, useEffect } from 'react';
import { Sun, MoonStars, Trash } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
    Box,
    Header,
    Menu,
    Image,
    Group,
    ActionIcon,
    useMantineColorScheme,
    useMantineTheme,
    MediaQuery,
    Burger, Avatar
} from '@mantine/core';

import { FiUser, FiLogOut, FiLock } from "react-icons/fi";


// API-Services
import { logoutService } from '../../service/API';
import { removeUserSession, getToken } from '../../utils/AuthService';
import { userUpdateProfile, userGetProfile } from '../../service/user'
// Images
import Logo from "../../assets/images/logo.png"
import LogoV2 from "../../assets/images/logo-main.png"

const AppHeader = ({ setOpened, opened }) => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showImage, setImage] = useState();

   // console.log('In profile image', showImage)
    
    const token = getToken();
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const handleProfile = () => (navigate('admin/profile'));
    const changePassword = () => (navigate('admin/change-password'));


    // const handleLogot = () => logoutService(navigate);
    const handleLogot = () => {
        logoutService(navigate)
        removeUserSession();
        // navigate('/'); 
    };

    const getProfile = async () => {
        const result = await userGetProfile({ token });
        if (result?.data?.status) {
            if (result?.data?.data?.photo) {
                setImage(result?.data?.data?.photo)
            } 

        }
    };
    useEffect(() => {
        getProfile()
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        }
    }, [])
    return (
        <Header p="xs"
            sx={() => ({
                height: '80px',
                '@media (max-width: 800px)': {
                    position: 'fixed',
                    width: '100%'
                }
            })}
        >
            <Group
                sx={() => ({
                    height: '100%'
                })}
                position="apart"
            >
                <Box sx={() => ({
                    display: 'flex',
                    alignItems: 'center'
                })}>
                    {
                        colorScheme === 'dark' ?
                            <Image
                                style={{
                                    height: '60px',
                                    width: '110px',
                                    margin: 0,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                src={LogoV2}
                                alt="Dashboard Logo"
                            />
                            :
                            <Image
                                style={{
                                    height: '60px',
                                    width: '110px',
                                    margin: 0,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                src={Logo}
                                alt="Dashboard Logo"
                            />
                    }
                    {/* <Title 
                    sx={() => ({
                        margin: '14px',
                        textAlign: 'center',
                        '@media (max-width: 568px)': {
                        display: 'none'
                        } 
                    })}
                    order={3}
                >
                Segwitz Sdn Bdh
                </Title> */}
                </Box>
                <Box sx={() => ({
                    display: 'flex',
                    alignItems: 'center'
                })}>
                    <Menu
                        sx={() => ({
                            margin: '0 30px',
                            '@media (max-width: 480px)': {
                                margin: '0 20px',
                            }
                        })}
                        control={
                            showImage ? <Avatar style={{ cursor: 'pointer' }}
                                src={showImage}
                                radius="xl"
                            /> :
                                <Avatar style={{ cursor: 'pointer' }}
                                    src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80"
                                    radius="xl"
                                />
                        }> 
                        <Menu.Item
                            onClick={handleProfile}
                            icon={<FiUser size={14} />}>
                            Profile
                        </Menu.Item>
                        <Menu.Item
                            onClick={changePassword}
                            icon={<FiLock size={14} />}>
                            Change Password
                        </Menu.Item>
                        <Menu.Item
                            onClick={handleLogot}
                            icon={<FiLogOut size={14} />}>
                            Logout
                        </Menu.Item>

                    </Menu>

                    {/* <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={30}>
                        {colorScheme === 'dark' ? <Sun size={16} /> : <MoonStars size={16} />}
                    </ActionIcon> */}

                    <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                        <Burger
                            opened={opened}
                            onClick={() => setOpened((o) => !o)}
                            size="sm"
                            color={theme.colors.gray[6]}
                            ml="xl"
                        />
                    </MediaQuery>
                </Box>
            </Group>
        </Header>
    )
}

export default AppHeader
