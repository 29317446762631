import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Card, Menu, Button, Image, Textarea, Modal, Group, Box, TextInput, Notification, ActionIcon } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons';
import { FiEdit, FiTrash, FiEye, FiMoreVertical } from "react-icons/fi";


// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service  
import { mechanicContactListingService, mechanicContactDataRemoveService } from '../../../../service/contactService';

// Redux-Settings
import {
    setMechanicContactList,
    setMechanicTotalEntry,
    selectStateMechanicContact,
} from '../../../../redux/contactSlice';

import UsersTable from './UserTable';
import MechanicTable from './MechanicTable';

const MyTabs = () => {
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const navigate = useNavigate();
    const token = getToken();


    return (
        <>
            <Card>
                <Tabs color="teal" defaultValue="first">
                <Tab label="User">
                <UsersTable/>
                </Tab>
                <Tab label="Mechanic">
                <MechanicTable/>
                </Tab>
                </Tabs>
            </Card>
        </>
    );
};

export default MyTabs;
