import React, { useState, useEffect } from 'react';
import { IconArrowUpRight, IconArrowDownRight, IconCheck, IconX, } from '@tabler/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
    createStyles, Group, Paper, Text, ThemeIcon, SimpleGrid,
    Box, Image, Switch, Card, Grid, RingProgress, Center, Icon
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// ** Third Party Components
import Select from 'react-select'

import Loading from '../../../../components/Loading/Loading';

// API-Service   
import { shortStatsService } from '../../../../service/dashboardService';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { setShortStats, selectShortStats } from '../../../../redux/dashboard';

const Stats = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectShortStats);
    const token = getToken();
    const [loading, setLoading] = useState(false);
    // console.log('data', data,)

    const useStyles = createStyles((theme) => ({
        root: {
            padding: theme.spacing.xl * 1.5,
        },
        label: {
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        },
    }));

    const fetchData = async () => {
        const result = await shortStatsService({ token });
        if (result?.data?.status) {
            setLoading(false);
            dispatch(setShortStats(result?.data?.data));
        } else {
            setLoading(false);
            ErrorToast(result);
        }
    };

    useEffect(() => {
        fetchData();
        const timeoutId = setTimeout(() => 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <>
            <Grid>
                <Grid.Col md={6} lg={6} sm={12} >
                    <Paper withBorder p="md" radius="md" key='' style={{ height: '130px' }}>
                        <Group position="apart">
                            <div>
                                <Text
                                    color="dimmed"
                                    transform="uppercase"
                                    weight={800}
                                    size="xl"
                                    className='abc'
                                >
                                    Total Customers
                                </Text>
                                <Text weight={800} size="xl">
                                    {data?.totalCustomers ? data?.totalCustomers : 0}
                                </Text>
                            </div>
                            <ThemeIcon
                                color="gray"
                                variant="light"
                                sx={(theme) => ({ color: theme.colors.teal[6] })}
                                size={38}
                                radius="md"
                            >
                                <IconArrowUpRight size={28} stroke={1.5} />
                            </ThemeIcon>
                        </Group>
                        {/* <Text color="dimmed" size="sm" mt="md">
                        <Text component="span" color='red' weight={800}>
                            %
                        </Text>{' '}
                        decrease compared to last month
                    </Text> */}
                    </Paper>
                </Grid.Col>
                <Grid.Col md={6} lg={6} sm={12} >
                    <Paper withBorder p="md" radius="md" key='' style={{ height: '130px' }} >
                        <Group position="apart">
                            <div>
                                <Text
                                    color="dimmed"
                                    transform="uppercase"
                                    weight={800}
                                    size="xl"
                                    className='abc'
                                >
                                    Total Mechanics
                                </Text>
                                <Text weight={800} size="xl">
                                    {data?.totalMechanics ? data?.totalMechanics : 0}

                                </Text>
                            </div>
                            <ThemeIcon
                                color="gray"
                                variant="light"
                                sx={(theme) => ({ color: theme.colors.teal[6] })}
                                size={38}
                                radius="md"
                            >
                                <IconArrowUpRight size={28} stroke={1.5} />
                            </ThemeIcon>
                        </Group>
                        {/* <Text color="dimmed" size="sm" mt="md">
                        <Text component="span" color='red' weight={800}>
                            %
                        </Text>{' '}
                        decrease compared to last month
                    </Text> */}
                    </Paper>
                </Grid.Col>
                <Grid.Col md={6} lg={6} sm={12}>
                    <Paper withBorder p="md" radius="md" key='' style={{ height: '130px' }}>
                        <Group position="apart">
                            <div>
                                <Text
                                    color="dimmed"
                                    transform="uppercase"
                                    weight={800}
                                    size="xl"
                                    className='abc'
                                >
                                    Total Freelancers
                                </Text>
                                <Text weight={800} size="xl">
                                    {data?.totalFreelancers ? data?.totalFreelancers : 0}
                                </Text>
                            </div>
                            <ThemeIcon
                                color="gray"
                                variant="light"
                                sx={(theme) => ({ color: theme.colors.teal[6] })}
                                size={38}
                                radius="md"
                            >
                                <IconArrowUpRight size={28} stroke={1.5} />
                            </ThemeIcon>
                        </Group>
                        {/* <Text color="dimmed" size="sm" mt="md">
                        <Text component="span" color='red' weight={800}>
                            %
                        </Text>{' '}
                        decrease compared to last month
                    </Text> */}
                    </Paper>
                </Grid.Col>
                <Grid.Col md={6} lg={6} sm={12}>
                    <Paper withBorder p="md" radius="md" key='' style={{ height: '130px' }}>
                        <Group position="apart">
                            <div>
                                <Text
                                    color="dimmed"
                                    transform="uppercase"
                                    weight={800}
                                    size="xl"
                                    className='abc'
                                >
                                    BreakDown Bookings
                                </Text>
                                <Text weight={800} size="xl">
                                    {data?.totalBreakdownBooking ? data?.totalBreakdownBooking : 0}
                                </Text>
                            </div>
                            <ThemeIcon
                                color="gray"
                                variant="light"
                                sx={(theme) => ({ color: theme.colors.teal[6] })}
                                size={38}
                                radius="md"
                            >
                                <IconArrowUpRight size={28} stroke={1.5} />
                            </ThemeIcon>
                        </Group>
                        {/* <Text color="dimmed" size="sm" mt="md">
                        <Text component="span" color='red' weight={800}>
                            %
                        </Text>{' '}
                        decrease compared to last month
                    </Text> */}
                    </Paper>
                </Grid.Col>
                <Grid.Col md={6} lg={6} sm={12}>
                    <Paper withBorder p="md" radius="md" key='' style={{ height: '130px' }}>
                        <Group position="apart">
                            <div>
                                <Text
                                    color="dimmed"
                                    transform="uppercase"
                                    weight={800}
                                    size="xl"
                                    className='abc'
                                >
                                    Service Bookings
                                </Text>
                                <Text weight={800} size="xl">
                                    {data?.totalServiceBooking ? data?.totalServiceBooking : 0}
                                </Text>
                            </div>
                            <ThemeIcon
                                color="gray"
                                variant="light"
                                sx={(theme) => ({ color: theme.colors.teal[6] })}
                                size={38}
                                radius="md"
                            >
                                <IconArrowUpRight size={28} stroke={1.5} />
                            </ThemeIcon>
                        </Group>
                        {/* <Text color="dimmed" size="sm" mt="md">
                        <Text component="span" color='red' weight={800}>
                            %
                        </Text>{' '}
                        decrease compared to last month
                    </Text> */}
                    </Paper>
                </Grid.Col>
                <Grid.Col md={6} lg={6} sm={12} >
                    <Paper withBorder p="md" radius="md" key=''  style={{ height: '130px' }}>
                        <Group position="apart">
                            <div>
                                <Text
                                    color="dimmed"
                                    transform="uppercase"
                                    weight={800}
                                    size="xl"
                                    className='abc'
                                >
                                    BreakDown Revenue
                                </Text>
                                <Text weight={800} size="xl">
                                    {data?.totalRevenueBreakdown ? `RM ${data?.totalRevenueBreakdown}` : 0}
                                </Text>
                            </div>
                            <ThemeIcon
                                color="gray"
                                variant="light"
                                sx={(theme) => ({ color: theme.colors.teal[6] })}
                                size={38}
                                radius="md"
                            >
                                <IconArrowUpRight size={28} stroke={1.5} />
                            </ThemeIcon>
                        </Group>
                        {/* <Text color="dimmed" size="sm" mt="md">
                        <Text component="span" color='red' weight={800}>
                            %
                        </Text>{' '}
                        decrease compared to last month
                    </Text> */}
                    </Paper>
                </Grid.Col>
                <Grid.Col md={6} lg={6} sm={12}>
                    {/* <Paper withBorder radius="md" p="md" key=''>
                        <Group>
                            <RingProgress
                                size={80}
                                roundCaps
                                thickness={8}
                                sections={[{ value: 89786 }]}
                                label={
                                    <Center>
                                        <IconArrowUpRight size={22} stroke={1.5} />
                                    </Center>
                                }
                            />

                            <div>
                                <Text color="dimmed" size="md" transform="uppercase" weight={800}>
                                    Revenue
                                </Text>
                                <Text weight={800} size="xl">
                                    {90988}
                                </Text>
                            </div>
                        </Group>
                    </Paper> */}
                    <Paper withBorder p="md" radius="md" key=''  style={{ height: '130px' }}>
                        <Group position="apart">
                            <div>
                                <Text
                                    color="dimmed"
                                    transform="uppercase"
                                    weight={800}
                                    size="xl"
                                    className='abc'
                                >
                                    Service Revenue
                                </Text>
                                <Text weight={800} size="xl">
                                    {data?.totalRevenueService ? `RM ${data?.totalRevenueService}` : 0}
                                </Text>
                            </div>
                            <ThemeIcon
                                color="gray"
                                variant="light"
                                sx={(theme) => ({ color: theme.colors.teal[6] })}
                                size={38}
                                radius="md"
                            >
                                <IconArrowUpRight size={28} stroke={1.5} />
                            </ThemeIcon>
                        </Group>
                        {/* <Text color="dimmed" size="sm" mt="md">
                        <Text component="span" color='red' weight={800}>
                            %
                        </Text>{' '}
                        decrease compared to last month
                    </Text> */}
                    </Paper>
                </Grid.Col>
                <Grid.Col md={6} lg={6} sm={12}>
                    <Paper withBorder p="md" radius="md" key=''  style={{ height: '130px' }}>
                        <Group position="apart">
                            <div>
                                <Text
                                    color="dimmed"
                                    transform="uppercase"
                                    weight={800}
                                    size="xl"
                                    className='abc'
                                >
                                    Pending Withdraws
                                </Text>
                                <Text weight={800} size="xl">
                                    {data?.totalPendingWithdraws ? data?.totalPendingWithdraws : 0}
                                </Text>
                            </div>
                            <ThemeIcon
                                color="gray"
                                variant="light"
                                sx={(theme) => ({ color: theme.colors.red[6] })}
                                size={38}
                                radius="md"
                            >
                                <IconArrowDownRight size={28} stroke={1.5} />
                            </ThemeIcon>
                        </Group>
                        {/* <Text color="dimmed" size="sm" mt="md">
                        <Text component="span" color='red' weight={800}>
                            %
                        </Text>{' '}
                        decrease compared to last month
                    </Text> */}
                    </Paper>
                </Grid.Col>
            </Grid>

        </>
    );
};

export default Stats;
