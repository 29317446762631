import React, { useState, useEffect, Fragment } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { Link, Routes, Route, useNavigate, Redirect } from 'react-router-dom'

 
import { Row, Col, Card, CardTitle, CardText, CardBody, Form, Label, Input, Button, FormFeedback, Toast, ToastBody, ToastHeader, Spinner } from 'reactstrap'


import { ErrorToast, SuccessToast } from '../../../../utils/utilities'
// Redux and Services Setting
import { deleteAccountService } from '../../../../service/otpVerification/otpService'
import { selectPhoneNumber, selectOtpNumber } from '../../../../redux/deleteAccount/otpSlice'
import Logo from '../../../../assets/images/logo.png'

const DeletePage = () => {
    const naviagte = useNavigate()
    const PhoneNumber = useSelector(selectPhoneNumber)
    console.log('phone number', PhoneNumber)
    const OtpNumber = useSelector(selectOtpNumber)
    const [loading, setLoading] = useState(false)
    console.log('Otp Number', OtpNumber)

    const handleSucessPage = async () => {
      try {
        setLoading(true)
    
        const inputs = {
          phone: PhoneNumber,
          otp: OtpNumber,
            
        }
  
        const result = await deleteAccountService(inputs).catch((error) => {
          setLoading(false)
          ErrorToast(error.response.data.message)
        })
  
        if (result.data.status === 'success') {
          setLoading(false)
          naviagte('/sucess-page')
          SuccessToast('Account deleted successfully')
        } else {
          setLoading(false)
          ErrorToast(result.data.message)
        }
      } catch (error) {
        setLoading(false)
        console.error('Error during OTP verification:', error)
      }
    }

    const handleCancel = () => {
      naviagte('/otp-verification')
    }

    return (
        
      <>
      <Row>
        <Col>
          <div className="p-3">
            <div className="d-flex flex-column align-items-center justify-content-center text-center">
              <img src={Logo} alt="logo" width="90px"  height="90px"/>
              <h2 className="mt-2">
                <span className="d-block d-md-inline">Arc Reforestation </span>
                
              </h2>
            </div>
          </div>
        </Col>
      </Row >
      <Row className='m-1 mt-50'>
      <div className="d-flex justify-content-center mt-1">
          <div className="bg-white p-3 d-flex flex-column col-md-5 col-12">
            <h5 className="fw-bold">Delete Account?</h5>
            <p className="mb-3">
              Deleting your account will remove all of your information from
              our database. This cannot be undone.
            </p>
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <Button
                type="button"
                className="btn  px-5 me-2 mb-2 mb-lg-0 col-12 col-lg-6"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="btn btn-danger px-5 col-12 col-lg-6"
                onClick={handleSucessPage}
              >
                Delete Account
              </Button>
            </div>
          </div>
        </div>
        </Row>


    </>
    )

}

export default DeletePage