import React, { useState, useEffect } from 'react';
import { Box, Image, Switch, Card, Grid } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';

// import ShortStats from './ShortStats'
import ShortStats from './Stats';
import Footer from '../../../../components/Footers/Footer';


const Dashboard = () => {
    const dispatch = useDispatch();

    return (
        <Card>
            <Grid>
                <Grid.Col xs={12}>
                    {/* <h1 className='text-left'>Welcome</h1> */}
                </Grid.Col>
                <Grid.Col xs={12}>
                    <ShortStats />
                </Grid.Col>
            </Grid>
        </Card>
    );
};

export default Dashboard;
