import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const userContactListingService = ({ page, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/contact-us/customer/list?paginate=1&page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
export const userContactSearchService = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/contact-us/customer/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const userContactUpdateStatusService = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/contact-us/customer/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const userContactDataRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/contact-us/customer/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  

export const mechanicContactListingService = ({ page, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/contact-us/mechanic/list?paginate=1&page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
export const mechanicContactSearchService = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/contact-us/mechanic/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const mechanicContactUpdateStatusService = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/contact-us/mechanic/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const mechanicContactDataRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/contact-us/mechanic/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );