
import React from 'react'
import { Loader, LoadingOverlay, Box } from '@mantine/core';

const Loading = () => (
        <Box style={{ width: '100%', height: '100vh', margin: 'auto', position: 'relative' }}>
            {/* <LoadingOverlay visible /> */}
            <Loader visible color="orange" />
        </Box>
    )

export default Loading
