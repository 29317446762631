import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const shortStatsService = ({ token }) =>
    axios.get(`${baseUrl}/admin/dashboard`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            },
        })
export const mechanicFileUploadService = (data, id, token) =>
    axios.post(`${baseUrl}/healthTips/manage/file?id=${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
        },
    });