import React, { useEffect, useState } from 'react';
import { Input } from '@mantine/core';
import { Search } from 'tabler-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getToken, removeUserSession } from '../../../../utils/AuthService';

// API-Service
import { userSearchService } from '../../../../service/mechanic.service';

// Redux-Settings
import { setMechanicServiceList } from '../../../../redux/mechanicServiceSlice';

const SearchForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (query) => {
    const result = await userSearchService({ search: query, countPerPage, token });
    if (result?.data?.status) {
      const responseData = result?.data?.data?.mechanic_services_list;
      setLoading(false);
      dispatch(setMechanicServiceList(responseData));
    }
  };

  const inputHandler = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearch(inputValue);

    // Fetch the data with the current input value
    fetchData(inputValue);
  };

  useEffect(() => {
    // Fetch the complete list when the component mounts
    fetchData('');
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <Input
      onChange={inputHandler}
      icon={<Search size={16} />}
      placeholder="Search By Name"
      style={{ minWidth: '300px' }}
      sx={() => ({
        '@media (max-width: 912px)': {
          marginBottom: '30px',
        },
      })}
    />
  );
};  

export default SearchForm;
