import React from 'react';
import ProfileChange from '../../../components/Auth/Profile'

 
function Profile() {
    return (
        <div>
            <div>
            <ProfileChange />
            </div>
        </div>
    ); 
}
export default Profile;