import React, { useState, useEffect } from 'react';
import { Menu, Button, Image, Textarea, Modal, Group, Box, TextInput, Notification, ActionIcon } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons';
import { FiEdit, FiTrash, FiEye, FiMoreVertical } from "react-icons/fi";


// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service  
import { mechanicContactListingService, mechanicContactDataRemoveService } from '../../../../service/contactService';
  
// Redux-Settings
import { 
  setMechanicContactList,
  setMechanicTotalEntry,
  selectStateMechanicContact,
} from '../../../../redux/contactSlice';

const DeleteUpdate = ({ tableRecord }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isNotification, setNotification] = useState(false);

  const [formValue, setFromValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detail = useSelector(selectStateMechanicContact);
  const token = getToken();

  // All Record-update Controller
//   const hendleUpdateModal = () => {
// 
//     navigate(`/admin/edit-coupon/${tableRecord?.id}`)

//     dispatch(setClickData())
//   };

  useEffect(() => {
    setFromValue(tableRecord);
  }, [tableRecord]);

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
  };

  const hendleDelete = async () => {
    const result = await mechanicContactDataRemoveService({
      id: tableRecord?.id,
      token,
    });
    // setIsDeleteModal(false);
    if (result?.data?.status) {
      SuccessToast(result.data.message)
      const resultAll = await mechanicContactListingService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setMechanicContactList(resultAll?.data?.data?.mechanic_contact_us_lists));
        dispatch(setMechanicTotalEntry(result?.data?.data?.pagination?.total));
        // SuccessToast(resultAll.data.message)
      } else {
        ErrorToast(resultAll.data.message);
      }
    }
    // setIsDeleteModal(false)
  };

  return (
    <Box>

      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
        {/* <Menu.Item onClick={hendleUpdateModal} icon={<FiEdit size={14} />}>
          Edit
        </Menu.Item> */}
        <Menu.Item
          onClick={hendleDeleteModal}
          icon={<FiTrash size={14} />}>
          Delete
        </Menu.Item>
      </Menu>
      { isNotification ? <Notification icon={<IconCheck size={18} />} color="teal" title="Notification">Coupon created successfully</Notification> : ''}


      <Modal opened={isDeleteModal} onClose={() => setIsDeleteModal(false)} title="Please Confirm" centered>
        <Group position="center">
          <Button color="dark" onClick={hendleCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={hendleDelete}>Delete</Button>
        </Group>
      </Modal>
    </Box>
  );
};

export default DeleteUpdate;
