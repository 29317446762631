import React, { useState, useEffect } from 'react';
import { Box, Image, Switch , Card} from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
 
import Loading from '../../../../components/Loading/Loading'; 

// API-Service   
import { dataListingServiceUserWebpageAboutUs } from '../../../../service/aboutService';

// Utility-Service
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Redux-Settings
import { setDataList, selectStateData, } from '../../../../redux/aboutSlice';

const ListingTable = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const Listing = useSelector(selectStateData);
  console.log("Listing", Listing)
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (filterTerm, page) => {
    const result = await dataListingServiceUserWebpageAboutUs({ filterTerm, page, countPerPage});
    if (result?.data?.status) {
      setLoading(false);
      dispatch(setDataList(result?.data?.data));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  }; 


  useEffect(() => {
    setLoading(true);
    fetchData();
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  const renderHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    
    // Select elements with specific classes
    const sansElements = doc.body.querySelectorAll('.ql-font-sans')
    const monospaceElements = doc.body.querySelectorAll('.ql-font-monospace')

    // Apply styles to elements with specific classes
    sansElements.forEach(element => {
        element.style.fontFamily = 'sans-serif'
    })

    monospaceElements.forEach(element => {
        element.style.fontFamily = 'monospace'
    })

    return doc.body.innerHTML
} 



  if (Listing?.length === 0 && loading) {
    return (
      <Box
      sx={() => ({
        margin: '0 auto',
        minHeight: 440,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      })}
    >
      <span className="visually-hidden"><Loading /></span>
    </Box>
    );
  }


  return (
    <Card>
      <Box>
        {Listing.value   ? (
          <div>
            <div className='d-flex'>
              <div style={{ marginRight: '10px' }}>
                <h4>About Us</h4>
              </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: renderHTML(Listing.value) }} />
          </div>
        ) : 'No data found'}
      </Box>
    </Card>
  );
};

export default ListingTable;
