import React from 'react';

import { 
    Title,
    Select, 
    Card
} from '@mantine/core';

const SelectElement = () => {
    const data = [
        { value: 'react', label: 'React' },
        { value: 'ng', label: 'Angular' },
        { value: 'svelte', label: 'Svelte' },
        { value: 'vue', label: 'Vue' },
    ]
    return(
        <Card 
            shadow="sm" 
            p="lg"
            sx={_ => ({
                maxWidth: 450,
                minWidth: 450,
                minHeight: 250,
                '@media (max-width: 1160px)': {
                    minWidth: 'auto',
                    width: 380
                },
                '@media (max-width: 1050px)': {
                    width: '90%',
                    margin: '0 auto'
                }
            })}
        >
            <Title order={3} style={{marginBottom: 30}}>
                Select
            </Title> 
            <Select
                label="Default Varient"
                placeholder="Pick one"
                data={data}
            />
            <Select 
                searchable
                clearable 
                label="Searchable Varient"
                placeholder="Pick one"
                data={data}
                />
        </Card>
    )}

export default SelectElement