import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const sosUserListSevice = ({   countPerPage, page, token }) =>
  axios.get(`${baseUrl}/admin/sos/list?paginate=1&page=${page}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });   

export const sosSearchListSevice = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/sos/list?keyword=${search}&paginate=1&&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const addSoSService = ({ token }, formData) =>
  axios.post(
    `${baseUrl}/admin/sos/store`, formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

export const bankStatusChange = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/bank/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const sosUpdateData = (
  { token },
  formData,
  // const id = formData.get('id')
) =>
  axios.post(
    `${baseUrl}/admin/sos/update/${formData.get('id')}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data',
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const sosDelete = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/sos/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


