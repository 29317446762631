
import React, {useState} from 'react';
import { MantineProvider, ColorSchemeProvider } from '@mantine/core';

import DashboardApp from "./view/DashboardApp"
// import Check from "./view/Pages/admin/index"
// import MyRoutes from "./router/index"
 
const App = () => {
  const [colorScheme, setColorScheme] =  useState('light');
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ 
        colorScheme,
        breakpoints: {
          xs: 500,
          sm: 800,
          md: 1000,
          lg: 1275,  
          xl: 1800,
        },
        colors: {
            // override dark colors here to change them for all components
            dark: [
              '#d5d7e0',
              '#acaebf',
              '#8c8fa3',
              '#666980',
              '#4d4f66',
              '#34354a',
              '#2b2c3d',
              '#1d1e30',
              '#0c0d21',
              '#01010a',
            ],
        },
    }} withGlobalStyles>
        <DashboardApp />
        {/* <Check/> */}
      </MantineProvider>
    </ColorSchemeProvider>
  ); 
}

export default App


