import { createSlice } from "@reduxjs/toolkit";
 
export const mechanicSlice = createSlice({
  name: "mechanic",
  initialState: {
    mechanicList: '',
    servicesList: '',
    filteredList: '',
    filterTerm: '',
    detail: {},
    myFile: {},
    totalEntry: 0 ,
    singleData: {},
  },   
  reducers: {   
    setMechanicList: (state, action) => {
      state.mechanicList = action.payload;
    },
    setNewServices: (state, action) => {
      state.servicesList = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },
    setSingleData: (state, action) => {
      state.singleData = action.payload;
    },
    setMechanicDetail: (state, action) => {
      state.detail = action.payload;
    },
    setTotalEntry: (state, action) => {
        state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setMechanicPhoto: (state, action) => {
      state.myFile = action.payload;
    },

  },
});  

export const {
    setMechanicList, 
    setNewServices,
    setFilterTerm,
    setMechanicDetail,
    setTotalEntry,
    setFilteredList,
    setMechanicPhoto,
    setSingleData,
} = mechanicSlice.actions;

export const selectMechanicList = (state) => state.mechanic.mechanicList; 
export const selectServicesList = (state) => state.mechanic.servicesList; 
export const selectStateData = (state) => state.mechanic.detail; 
export const selectFilterTerm = (state) => state.mechanic.filterTerm; 
export const selectTotalEntry = (state) => state.mechanic.totalEntry; 
export const selectFilteredList = (state) => state.mechanic.filteredList; 
export const selectStateSingleData = (state) => state.mechanic.singleData; 


export default mechanicSlice.reducer;
