
import axios from "axios"

// Auth-Services

import {
    setUserSession,
    removeUserSession,
    setTemporarySession,
    removeTemporarySession
} from "../utils/AuthService";

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

const url = 'https://jsonplaceholder.typicode.com'

const fakeAuth = () =>
    new Promise((resolve) => {
        setTimeout(() => resolve('2342f2f1d131rf12'), 250);
    });

const user = {
    name: 'Admin',
    email: 'admin@mail.com'
}

// export const loginService = async (navigate) => {
//     const token = await fakeAuth();
//     setUserSession(token, user);
//     navigate('/');
// };

export const logoutService = (navigate) => {
    removeUserSession();
    navigate('/login');
};

export const loginService = async ({ setError }, navigate, data) => {
    try {
        const result = await axios.post(`${baseUrl}/admin/login`, data,
            {
                headers: {
                    // "Authorization": `Bearer ${token}`,
                    "Accept": "application/json"
                }
            }
        )
        if (result) {
            setUserSession();
            navigate('/');
            // return result
        }
    } catch (err) {
        const showError = err.response.data.message
        setError(showError)
    }
    return false
}


export const postListService = async ({ token = null, setError, setLoading }) => {
    try {
        const result = await axios.get(`${url}/posts`,
            // {
            //     headers: {
            //         "Authorization": `Bearer ${token}`,
            //         "Accept": "application/json"
            //     }
            // }
        )

        if (result) {
            return result
        }
    } catch (err) {
        setLoading(false)
        setError("Can't fetch posts data")
    }
    return false
}

export const getData = (url2) =>
(fetch(url2)
    .then(rsp => {
        if (!rsp.ok) {
            throw Error("There was a problem fetching data.")
        }
        return rsp.json();
    }))
