import { createSlice } from "@reduxjs/toolkit";
 
export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customerList: [],
    mechanicList: [],
    filteredList: [],
    filterTerm: '',
    myFile: '',
    totalEntry: 0
  },          
  reducers: {  
    setCustomerList: (state, action) => {
      state.customerList = action.payload;
    },
    setMechanicList: (state, action) => {
      state.mechanicList = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },
    setTotalEntry: (state, action) => {
        state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setMechanicPhoto: (state, action) => {
      state.myFile = action.payload;
    },

  },
});

export const {
    setCustomerList, 
    setMechanicList,
    setFilterTerm,
    setTotalEntry,
    setFilteredList,
    setMechanicPhoto,
} = customerSlice.actions;

export const selectCustomerList = (state) => state.customer.customerList;
export const selectMechanicList = (state) => state.customer.mechanicList;  
export const selectFilterTerm = (state) => state.customer.filterTerm; 
export const selectTotalEntry = (state) => state.customer.totalEntry; 
export const selectFilteredList = (state) => state.customer.filteredList; 


export default customerSlice.reducer;
