import React from 'react';
import { 
    Title,
    MultiSelect,
    Card 
} from '@mantine/core';

const MultiSelectElement = () => {

    const data = [
        { value: 'react', label: 'React' },
        { value: 'ng', label: 'Angular' },
        { value: 'svelte', label: 'Svelte' },
        { value: 'vue', label: 'Vue' },
        { value: 'riot', label: 'Riot' },
        { value: 'next', label: 'Next.js' },
        { value: 'blitz', label: 'Blitz.js' },
    ];
      
    return(
        <Card 
        shadow="sm" 
        p="lg"
        sx={_ => ({
            maxWidth: 450,
            minWidth: 450,
            minHeight: 250,
            '@media (max-width: 1160px)': {
                minWidth: 'auto',
                width: 380
            },
            '@media (max-width: 1050px)': {
                width: '90%',
                margin: '0 auto'
            }
        })}
        >
            <Title order={3} style={{marginBottom: 30}}>
                Multi Select
            </Title> 
            <MultiSelect
                data={data}
                label="Multi select dropdown"
                placeholder="Pick all that you like"
            />
            <MultiSelect
                data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                label="Multi select clerable dropdown"
                placeholder="Pick all that you like"
                defaultValue={['react', 'next']}
                clearButtonLabel="Clear selection"
                clearable
            />
        </Card>
    )
}

export default MultiSelectElement