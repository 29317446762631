import React, { useState, useEffect } from 'react';
import { Menu, Button, Image, Textarea, Modal, Group, Box, TextInput, ActionIcon } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash, FiEye, FiMoreVertical,  } from "react-icons/fi";

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';
import FileUpload from './FileUploader';
  
// API-Service  
import { sosDelete, sosUpdateData, sosUserListSevice } from '../../../../service/sosService';

// Redux-Settings
import { setSosList, setTotalEntry, setFilteredList, setClickData, selectSosDetail } from '../../../../redux/sosSlice';

const DeleteUpdate = ({ tableRecord, fetch }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [file, setFile] = useState();
  const [showImage, setImage] = useState();
  const [formValue, setFromValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const detail = useSelector(selectMechanicServiceDetail);
  const token = getToken();

  // All Record-update Controller
  const hendleUpdateModal = () => {
    setIsUpdateModal(true);
    dispatch(setClickData())
  };

  const changeHandler = (event) => {
     setFile(event.target.files[0])
     setImage(URL.createObjectURL(file))
  };
  
  useEffect(() => {
    setFromValue(tableRecord);
  }, [tableRecord]);


  const handleTitleChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handlePhoneChange = (e) => {
    setFromValue((prevState) => ({
        ...prevState,
        number: e.target.value,
    }));
};

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
    setIsUpdateModal(false);
  };

  const hendleDelete = async () => {
    const result = await sosDelete({
      id: tableRecord?.id,
      token,
    });
    setIsDeleteModal(false);
    if (result?.data?.status) {
      SuccessToast(result.data.message)
      const resultAll = await sosUserListSevice({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setSosList(resultAll?.data?.data?.sos_lists));
        dispatch(setTotalEntry(resultAll?.data?.data?.sos_lists.pagination.total));
        
      } else {
        ErrorToast(resultAll.data.message);
      }
    } 
    // setIsDeleteModal(false)
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    if (file) {
      formData.append('image', file)
    }
      formData.append('id', formValue?.id)
      formData.append('name', formValue?.name)
      formData.append('number', formValue?.number)
      formData.append('token', token)
      formData.append('_method', 'PUT')
    const result = await sosUpdateData({ token }, formData);

    if (result?.data?.status) {
      // Show success toast
      SuccessToast('User updated successfully')

      // Call fetch callback function to fetch updated data
      fetch()

      // Close update modal
      setIsUpdateModal(false)
    } else {
      // Show error toast or handle other cases
      ErrorToast('Failed to update user')
    }
  }
  
  

  return (
    <Box>  
      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
        <Menu.Item onClick={hendleUpdateModal} icon={<FiEdit size={14} />}>
          Edit
        </Menu.Item>
        <Menu.Item
          onClick={hendleDeleteModal}
          icon={<FiTrash size={14} />}>
          Delete
        </Menu.Item>
      </Menu>

      <Modal opened={isDeleteModal} onClose={() => setIsDeleteModal(false)} title="Are you sure want to delete ?" centered>
        <Group position="center">
          <Button variant="outline" onClick={hendleCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={hendleDelete}>Delete</Button>
        </Group>
      </Modal>
      
      <Modal opened={isUpdateModal} onClose={() => setIsUpdateModal(false)} title="Update User" centered>
        <form onSubmit={(values) => handleUpdate(values)}>
          <TextInput 
          required 
          label="Name"   
          value={formValue?.name || ''} 
          onChange={handleTitleChange} />

           <TextInput 
          required 
          label="Phone Number"   
          value={formValue?.number || ''} 
          onChange={handlePhoneChange} />

          { formValue.image ? <div style={{ marginTop: 15 }}>

          <Image src={formValue?.image} 
          alt="Service Image"
            sx={(_) => ({
              height: '80px',  
              width: '80px',
              overflow: 'hidden',
            })}/>
          </div>  : ''}


          <div style={{ marginTop: 15 }}>
            <TextInput label="Select Photo" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
          </div>

         
        
          <Group position="center" mt="md">
            <Button color="dark" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button color="yellow" type="submit">Update</Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default DeleteUpdate;
