import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, UseFormReturnType } from '@mantine/form';
import { Box, Card, Image, Text, TextInput, Button, Title, Group, Grid } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

// Utility-Service
import { getToken, setUserSession } from '../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../utils/utilities';

import { userUpdateProfile, userGetProfile } from '../../service/user'
import { setUserPassword } from '../../redux/userSlice';

import Loading from '../Loading/Loading';

/* . 
    Props-Function: 
    1. logo props for logo-image path
        > Value-type: string
    2. LogoVariantInside for set logo in inside of Card Component
        >  Value-type: Boolean
    2. LogoVariantOutside for set logo in outside of Card Component
        >  Value-type: Boolean
*/

const Login = ({ logo = null, LogoVariantInside = false, LogoVariantOutside = false, shadow = 'lg' }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = getToken();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [file, setFile] = useState();
    const [showImage, setImage] = useState();
    const [formValue, setFromValue] = useState('');
    const [inputError, setInputError] = useState(false);
    const form = useForm({
        // initialValues: {
        //     oldPassword: '',
        //     newPassword: '',
        //     passwordConfirmation: ''
        // },
        // validate: {
        //     oldPassword: (value) => (value.length < 2 ? 'Please enter old password' : null),
        //     newPassword: (value) => (value.length < 2 ? 'Please enter new password' : null),
        //     passwordConfirmation: (value) => (value.length < 2 ? 'Please enter repeat new password' : null),
        // },

    });

    const handleNameChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            name: e.target.value,
        }));
    };
    const handleEmailChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            email: e.target.value,
        }));
    };
    const handlePhoneChange = (e) => {
        setInputError(false);
        setFromValue((prevState) => ({
            ...prevState,
            phone: e.target.value,
        }));
    };

    const changeHandler = (event) => {
        setFile(event.target.files[0])
        // setImage(URL.createObjectURL(file))
        setImage(URL.createObjectURL(event.target.files[0]));
    };

    const updateProfileData = async (values, e) => {
        // e.preventDefault();
        setLoading(true);
        const formData = new FormData()
        if (file) {
            formData.append('image', file)
        }
        formData.append('id', formValue?.id)
        formData.append('name', formValue?.name)
        formData.append('email', formValue?.email)
        formData.append('phone', formValue?.phone)
        console.log('token', token)
        const result = await userUpdateProfile({ token }, formData)
        if (result?.data?.status) {
            setLoading(false);
            SuccessToast(result.data?.message);
            navigate('/admin/dashboard');
        } else {
            setLoading(false)
            ErrorToast(result);
        } 
        return false;
    }


    useEffect(() => {
        setLoading(true);
        const fetchPasswordData = async () => {
            const result = await userGetProfile(
                { token },
                { setError, setLoading });
            if (result?.data?.status) {
                const passwordData = result?.data?.data
                dispatch(setFromValue(passwordData))
            }
        }
        fetchPasswordData();
        const timeoutId = setTimeout(() => setLoading(false), 3000);
        return function cleanup() {
            clearTimeout(timeoutId);
        }
    }, [])

    if (loading) {
        return <Card>
            <div style={{
                margin: 'auto',
                textAlign: 'center'
            }}><Loading /></div>
        </Card>
    }

    return (
        <Box style={{ width: '100%' }}>
            {logo && LogoVariantOutside && (
                <Image
                    style={{
                        width: '110px',
                        margin: '50px auto'
                    }}
                    src={logo}
                    alt="Dashboard Logo"
                />
            )}
            <Card style={{ width: '100%' }} shadow={!shadow ? shadow : 'lg'} padding="xl">
                <Title
                    style={{
                        margin: '14px auto',
                        textAlign: 'left'
                    }}
                    order={3}
                >
                    Profile Change
                </Title>

                <form onSubmit={form.onSubmit((values, e) => updateProfileData(values, e))}>
                    <Grid>
                        <Grid.Col span={6}>
                            <TextInput required label="Name" placeholder="Enter here" value={formValue?.name || ""} onChange={handleNameChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter name</Text>}
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput required type="email" label="Email" placeholder="Enter here" value={formValue?.email || ""} onChange={handleEmailChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter email</Text>}
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput required type="number" label="Phone" placeholder="Enter here" value={formValue?.phone || ""} onChange={handlePhoneChange} />
                            {inputError && <Text style={{ color: '#EE7297' }}>Please enter phone</Text>}
                        </Grid.Col>


                        <Grid.Col span={6}>
                            <div style={{ marginTop: 0 }}>
                                <TextInput label="Select Photo" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
                            </div>

                            {
                                showImage ?
                                    <div style={{ marginTop: 15 }}>
                                        <Image src={showImage}
                                            alt="Profile Picture"
                                            sx={(_) => ({
                                                height: '60px',
                                                width: '60px',
                                                overflow: 'hidden',
                                            })} />
                                    </div>
                                    : <div style={{ marginTop: 15 }}>

                                        <Image src={formValue?.photo}
                                            alt="Profile Picture"
                                            sx={(_) => ({
                                                height: '80px',
                                                width: '80px',
                                                overflow: 'hidden',
                                            })} />
                                    </div>
                            }

                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Group position="left" mt="sm">
                                <Button
                                    type="reset"
                                    variant="filled"
                                    color="dark"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="filled"
                                    type="submit"
                                    color="orange"
                                >
                                    Save
                                </Button>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </form>
            </Card>
        </Box>
    );
};

export default Login;

// Login.prototypes = {
//     children: PropTypes.string
// };
