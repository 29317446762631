import { createSlice } from "@reduxjs/toolkit";
 
export const bookingSlice = createSlice({
  name: "coupon",
  initialState: {
    bookingListing: [],
    filteredList: [],
    filterTerm: '',
    clickSave: {},
    singleData: {},
    totalEntry: 0 
  },     
  reducers: {  
    setDataList: (state, action) => {
      state.bookingListing = action.payload;
    },
    setSingleData: (state, action) => {
      state.singleData = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },
    setTotalEntry: (state, action) => {
        state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setPhoto: (state, action) => {
      state.myFile = action.payload;
    },
    setClickData: (state, action) => {
      state.clickSave = action.payload;
    },

  }, 
}); 

export const {
    setDataList, 
    setSingleData,
    setFilterTerm,
    setClickData,
    setTotalEntry,
    setFilteredList,
    setPhoto,
} = bookingSlice.actions;

export const selectStateData = (state) => state.bookings.bookingListing; 
export const selectFilterTerm = (state) => state.bookings.filterTerm; 
export const selectTotalEntry = (state) => state.bookings.totalEntry; 
export const selectFilteredList = (state) => state.bookings.filteredList; 
export const selectStateDetail = (state) => state.bookings.clickSave;
export const selectStateSingleData = (state) => state.bookings.singleData; 


export default bookingSlice.reducer;
