import React, { useState, useEffect } from 'react';
import { Menu, Button, Textarea, Modal, Group, Box, TextInput, ActionIcon } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash, FiEye, FiMoreVertical, } from "react-icons/fi";

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service
import { mechanicListService, mechanicUpdateService, mechanicRemoveService } from '../../../../service/mechanic';

// Redux-Settings
import { setMechanicList, setTotalEntry, setFilteredList } from '../../../../redux/mechanicSlice';

const DeleteUpdate = ({ tableRecord }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);

  const [formValue, setFromValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  // All Record-update Controller
  const hendleUpdateModal = () => {
    // setIsUpdateModal(true);
    navigate(`/admin/update-mechanic-profile/${tableRecord?.id}`)
  };

  useEffect(() => {
    setFromValue(tableRecord);
  }, [tableRecord]);


  const handleTitleChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const handleDescriptionChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };

  const handleWebLinkChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      webLink: e.target.value,
    }));
  };

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
    setIsUpdateModal(false);
  };

  const hendleDelete = async () => {
    const result = await mechanicRemoveService({
      id: tableRecord?.id,
      token,
    });

    if (result?.data?.status) {
      SuccessToast(result.data.message)
      setIsDeleteModal(false);
      const resultAll = await mechanicListService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setMechanicList(resultAll?.data?.data?.mechanics));
        dispatch(setFilteredList(resultAll?.data?.data?.mechanics));
        dispatch(setTotalEntry(result?.data?.data?.mechanics.length));
        SuccessToast(resultAll.data.message)
      } else {
        ErrorToast(resultAll);
      }
    }
    // setIsDeleteModal(false)
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const result = await mechanicUpdateService({
      id: formValue?.id,
      name: formValue?.name,
      image: formValue?.image,
      token,
    });

    if (result?.data?.status) {
      SuccessToast(result.data.message)
      setIsUpdateModal(false);
      const resultAll = await mechanicListService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.success) {
        dispatch(setMechanicList(resultAll?.data?.data?.list));
        dispatch(setTotalEntry(result?.data?.data?.count));
      } else {
        ErrorToast(resultAll);
      }
    } else {
      ErrorToast(result);
    }
    setIsUpdateModal(false);
  };

  return (
    <Box>

      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
        <Menu.Item onClick={hendleUpdateModal} icon={<FiEdit size={14} />}>
          Edit
        </Menu.Item>
        <Menu.Item
          onClick={hendleDeleteModal}
          icon={<FiTrash size={14} />}>
          Delete
        </Menu.Item>
      </Menu>

      <Modal opened={isDeleteModal} onClose={() => setIsDeleteModal(false)} title="Are you sure want to delete ?" centered>
        <Group position="center">
          <Button color="dark" onClick={hendleCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={hendleDelete}>Delete</Button>
        </Group>
      </Modal>
      <Modal opened={isUpdateModal} onClose={() => setIsUpdateModal(false)} title="Update Mechanic" centered>
        <form onSubmit={(values) => handleUpdate(values)}>
          <TextInput
            required
            label="Name"
            value={formValue?.title || ''}
            onChange={handleTitleChange} />

          <Textarea
            minRows={7}
            style={{ marginTop: 15 }}
            required
            label="Description"
            value={formValue?.description || ''}
            onChange={handleDescriptionChange}
          />

          <TextInput
            required
            style={{ marginTop: 15 }}
            label="Link"
            value={formValue?.webLink || ''}
            onChange={handleWebLinkChange}
          />
          <Group position="center" mt="md">
            <Button color="dark" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button color="yellow" type="submit">Update</Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default DeleteUpdate;
