import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const mechanicListService = ({ countPerPage, page, token }) =>
  axios.get(`${baseUrl}/admin/mechanic/list?paginate=1&page=${page}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });  

export const getAllListService = ({ token }) =>
  axios.get(`${baseUrl}/admin/mechanic-service/list?`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });  

export const userSearchService = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/mechanic/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

export const mechanicCreateService = ({ name, image, token }) =>
  axios.post(
    `${baseUrl}/admin/mechanic/store`,
    {
      name,
      image,
    },  
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

export const mechanicUpdateStatusService = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/mechanic/subscription-status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  ); 

  export const mechanicProfileUpdateStatusService = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/mechanic/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  ); 

export const mechanicDetail = ({ id, token }) =>
  axios.get(
    `${baseUrl}/admin/mechanic/single/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
  export const UpdateStatusService = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/mechanic/status/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

  export const getSingleDataService = ({ id, token }) =>
  axios.get(`${baseUrl}/admin/mechanic/single/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
   
// export const mechanicUpdateService = ({
//   id,
//   title,
//   description,
//   webLink,
//   token
// }) =>
//   axios.post(
//     `${baseUrl}/admin/mechanic/edit/${id}`,
//     {
//       title,
//       description,
//       type: '',
//       webLink,
//     },
//     { 
//       headers: {
//         Authorization: `Bearer ${token}`,
//         Accept: 'application/json',
//       },
//     }
//   );

  export const mechanicUpdateService = (
    { token, id },
    formData,
    // const id = formData.get('id')
  ) =>
    axios.post(
      `${baseUrl}/admin/mechanic/edit/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'multipart/form-data',
          "Content-Type": "multipart/form-data",
        },
      }
    );

export const mechanicRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/mechanic/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const mechanicFileUploadService = (data, id, token) =>
  axios.post(`${baseUrl}/healthTips/manage/file?id=${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });