
import React from 'react'
import { Box, Card } from '@mantine/core';

import MechanicListingTable from './ListingTable';
// import FilterMenu from './Filter';
import SearchForm from "./Search";
import Footer from '../../../../components/Footers/Footer';
  
const Mechanic = () => (
        <Card>
           
            <MechanicListingTable />
            {/* <Footer /> */}
        </Card>
    )

export default Mechanic
