import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

export const dataListingService = ({ countPerPage, page, token }) =>
   axios.get(`${baseUrl}/admin/breakdown/list?paginate=1&perPage=${countPerPage}&page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

  export const userSearchService = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/breakdown/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });

  export const bookingFilterService = ({ filterTag, countPerPage, token }) =>
   axios.get(`${baseUrl}/admin/breakdown/list?paginate=1&booking_current_status=${filterTag}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  
  export const serviceDetail = ({ id, token }) =>
  axios.get(
    `${baseUrl}/admin/service-booking/single/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  ); 

  export const bookingDetail = ({ id, token }) =>
  axios.get(
    `${baseUrl}/admin/breakdown/single/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

export const cancelBookingService = ({ token }, formData) =>
  axios.post(
    `${baseUrl}/admin/breakdown/cancel-booking`, formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    } 
  );

export const UpdateStatusService = ({ id, token }) =>
  axios.post(
    `${baseUrl}/coupons/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );

export const UpdateSingleDataService = (
  { token, id },
  input,
  // const id = formData.get('id')
) =>
  axios.put(
    `${baseUrl}/coupons/${id}`,
    input,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data',
        "Content-Type": "multipart/form-data",
      },
    }
  );

export const dataRemoveService = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/coupons/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const FileUploadService = (data, id, token) =>
  axios.post(`${baseUrl}/healthTips/manage/file?id=${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });