import React, { useEffect, useState } from 'react';
import { Input } from '@mantine/core';
import { Search } from 'tabler-icons-react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getToken, removeUserSession } from '../../../../utils/AuthService';

// API-Service
import { userSearchService } from '../../../../service/mechanic';

// Redux-Settings
import { setMechanicList } from '../../../../redux/mechanicSlice';

const SearchForm = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState();


    const token = getToken();
    const navigate = useNavigate();

    const [pageCount, setPageCount] = useState(1);
    const countPerPage = 10;

    const fetchData = async (abc) => {
        const result = await userSearchService({ search:abc, countPerPage, token });
        if (result?.data?.status) {
            const responseData = result?.data?.data?.mechanics
            setLoading(false);
            dispatch(setMechanicList(responseData));

        }
    };

    const inputHandler = (e) => {
        const inputValue = e.target.value.toLowerCase();
        setSearch(inputValue);
        
        if (inputValue) {
            fetchData(inputValue);
        } else {
            // Fetch the complete list when input is empty
            fetchData('');
        }
    };

    return (
        <Input
            onChange={inputHandler}
            icon={<Search size={16} />}
            placeholder="Search By Legal Name"
            style={{ minWidth: '300px' }}
            sx={() => ({
                '@media (max-width: 912px)': {
                    marginBottom: '30px'
                }
            })}
        />
    )
}

export default SearchForm;