import { createSlice } from "@reduxjs/toolkit";
 
export const sosSlice = createSlice({
  name: "sos",
  initialState: {
    sosList: [],
    myFile: '',
    clickSave: {},
    totalEntry: 0 ,
    filterTerm: '',
  },     
  reducers: {  
    setSosList: (state, action) => {
      state.sosList = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },
    setTotalEntry: (state, action) => {
        state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setSosPhoto: (state, action) => {
      state.myFile = action.payload;
    },
    setClickData: (state, action) => {
      state.clickSave = action.payload;
    },

  },
});

export const {
    setSosList, 
    setFilterTerm,
    setClickData,
    setTotalEntry,
    setFilteredList,
    setSosPhoto,
} = sosSlice.actions;

export const selectSosList = (state) => state.sos.sosList; 
export const selectFilterTerm = (state) => state.sos.filterTerm; 
export const selectTotalEntry = (state) => state.sos.totalEntry; 
export const selectSosDetail = (state) => state.sos.clickSave;
export const selectSosPhoto = (state) => state.sos.setsosPhoto; 
export const selectFilteredList = (state) => state.sos.filteredList; 


export default sosSlice.reducer;
