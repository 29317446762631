
import React, { useState } from 'react'

import Loading from '../../../components/Loading/Loading';
import RichEditor from '../../../components/Editor/RichEditor';
import Agreement from '../../../components/Agreement/Agreement';
import Footer from '../../../components/Footers/Footer';
  

const initialValue =
  '<p>Your initial <b>html value</b> or an empty string to init editor without value</p>';

const TermsAgreement = () => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [editorValue, editorOnChange] = useState(initialValue);

    if(loading){
        return <Loading/>
    }

    return(
        <>
            <RichEditor 
                value = {editorValue}
                onChange = {editorOnChange}
            />
            <Agreement />
            <Footer/>
        </>
    )
}
export default TermsAgreement
