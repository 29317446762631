import { createSlice } from "@reduxjs/toolkit";
 
export const bankSlice = createSlice({
  name: "bank",
  initialState: {
    banksList: [],
    myFile: '',
    clickSave: {},
    totalEntry: 0 ,
    filterTerm: '',
  },     
  reducers: {  
    setBankList: (state, action) => {
      state.banksList = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },
    setTotalEntry: (state, action) => {
        state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setBankPhoto: (state, action) => {
      state.myFile = action.payload;
    },
    setClickData: (state, action) => {
      state.clickSave = action.payload;
    },

  },
});

export const {
    setBankList, 
    setFilterTerm,
    setClickData,
    setTotalEntry,
    setFilteredList,
    setBankPhoto,
} = bankSlice.actions;

export const selectBankList = (state) => state.bank.banksList; 
export const selectFilterTerm = (state) => state.bank.filterTerm; 
export const selectTotalEntry = (state) => state.bank.totalEntry; 
export const selectBankDetail = (state) => state.bank.clickSave;
export const selectBankPhoto = (state) => state.bank.setMechanicPhoto; 
export const selectFilteredList = (state) => state.bank.filteredList; 


export default bankSlice.reducer;
