
import React from 'react';
import { 
    Title,
    InputWrapper,
    TextInput,
    Input,
    Card
} from '@mantine/core';

const InputElement = () =>(
    <Card 
        shadow="sm" 
        p="lg"
        sx={_ => ({
            maxWidth: 450,
            minWidth: 450,
            minHeight: 250,
            '@media (max-width: 1160px)': {
                minWidth: 'auto',
                width: 380
            },
            '@media (max-width: 1050px)': {
                width: '90%',
                margin: '0 auto'
            }
        })}
    >
        <Title order={3} style={{marginBottom: 30}}>
            Inputs
        </Title> 
        <InputWrapper label="Input label">
            <TextInput />
        </InputWrapper>
        <InputWrapper
            id="input-demo"
            required
            label="Credit card information"
            error="Your credit card expired"
            >
            <Input id="input-demo" placeholder="Your email" />
        </InputWrapper>
    </Card>
)

export default InputElement