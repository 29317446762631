import { createSlice } from "@reduxjs/toolkit";
 
export const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    dataListing: [],
    filteredList: [],
    filterTerm: '',
    myFile: '',
    clickSave: {},
    singleData: {},
    totalEntry: 0  
  },     
  reducers: {  
    setDataList: (state, action) => {
      state.dataListing = action.payload;
    },
    setSingleData: (state, action) => {
      state.singleData = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },
    setTotalEntry: (state, action) => {
        state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setPhoto: (state, action) => {
      state.myFile = action.payload;
    },
    setClickData: (state, action) => {
      state.clickSave = action.payload;
    },

  },
});

export const {
    setDataList, 
    setSingleData,
    setFilterTerm,
    setClickData,
    setTotalEntry,
    setFilteredList,
    setPhoto,
} = couponSlice.actions;

export const selectStateData = (state) => state.coupons.dataListing; 
export const selectFilterTerm = (state) => state.coupons.filterTerm; 
export const selectTotalEntry = (state) => state.coupons.totalEntry; 
export const selectFilteredList = (state) => state.coupons.filteredList; 
export const selectStateDetail = (state) => state.coupons.clickSave;
export const selectStateSingleData = (state) => state.coupons.singleData; 


export default couponSlice.reducer;
