import React from 'react';

import {
    Box,
    Button,
    Title,
    Card
} from '@mantine/core';

const ButtonElement = () => (
    <Card 
            shadow="sm" 
            p="lg"
            sx={_ => ({
                maxWidth: 450,
                minWidth: 450,
                minHeight: 250,
                '@media (max-width: 1160px)': {
                    minWidth: 'auto',
                    width: 380
                },
                '@media (max-width: 1050px)': {
                    width: '90%',
                    margin: '0 auto'
                }
            })}
    >
        <Title order={3} style={{marginBottom: 30}}>
            Button
        </Title> 
        <Box style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px'
        }}>
            <Button variant="light">
                Light variant
            </Button>
            <Button variant="filled">
                Filled variant
            </Button>
            <Button variant="outline">
                Outline variant
            </Button>
            <Button variant="default">
                Default variant
            </Button>
            <Button variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }}>
                Gradient variant
            </Button>
            <Button variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>Gradient variant</Button>
            <Button variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}>Gradient variant</Button>
        </Box>
    </Card>
)
export default ButtonElement