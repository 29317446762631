import { createSlice } from "@reduxjs/toolkit";
 
export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    UsersContact: [],
    UsersTotalEntry: 0 , 
    MechanicContact: [],
    MechanicTotalEntry: 0 , 
  },       
  reducers: {  
    setUserContactList: (state, action) => {
      state.UsersContact = action.payload;
    },
    setMechanicContactList: (state, action) => {
      state.MechanicContact = action.payload;
    },
    setMechanicTotalEntry: (state, action) => {
        state.MechanicTotalEntry = action.payload;
    },
    setUserTotalEntry: (state, action) => {
      state.UsersTotalEntry = action.payload;
  },

  },   
});
 
export const {
    setUserContactList, 
    setMechanicContactList,
    setUserTotalEntry,
    setMechanicTotalEntry,

} = contactSlice.actions;

export const selectStateUserContact = (state) => state.contact.UsersContact; 
export const selectStateMechanicContact = (state) => state.contact.MechanicContact;
export const selectUserTotalEntry = (state) => state.contact.UsersTotalEntry; 
export const selectMechanicTotalEntry = (state) => state.contact.MechanicTotalEntry; 

export default contactSlice.reducer;
