import { createSlice } from "@reduxjs/toolkit";
 
export const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    transactions: [],
    TotalEntry: 0 , 
  },             
  reducers: {  
    setTransactionList: (state, action) => {
      state.transactions = action.payload;
    },
    setTotalEntry: (state, action) => {
      state.TotalEntry = action.payload;
  },

  },
});
 
export const {
  setTransactionList, 
    setTotalEntry,

} = transactionSlice.actions;

export const selectStateTransaction = (state) => state.transaction.transactions; 
export const selectTotalEntry = (state) => state.transaction.TotalEntry; 

export default transactionSlice.reducer;
