import { createSlice } from "@reduxjs/toolkit";

export const privacySlice = createSlice({
  name: "privacy ",
  initialState: { 
    privacyList: null,
    filteredPrivacyList: null
  },
  reducers: { 
    setPrivacyList: (state, action) => {
      state.privacyList = action.payload;
    },  
    addPrivacy: (state, action) => {
      state.privacyList.push(action.payload)
      state.filteredPrivacyList.push(action.payload)
    },
    setFilteredPrivacyList: (state, action) => {
        state.filteredPrivacyList = action.payload;
    },
    updatePrivacy: (state, action) => {
      const index = state.filteredPrivacyList.findIndex((item) => item.id === action.payload.id);
      state.filteredPrivacyList[index] = action.payload;
    },
    deletePrivacy: (state, action) => {
      state.filteredPrivacyList = state.filteredPrivacyList?.filter(item => item.id !== action.payload);
    }
  },
});

export const {
    setPrivacyList,
    addPrivacy,
    updatePrivacy,
    deletePrivacy,
    setFilteredPrivacyList
} = privacySlice.actions;

export const selectPrivacyList = (state) => state.privacy.privacyList;
export const selectFilteredPrivacyList = (state) => state.privacy.filteredPrivacyList;

export default privacySlice.reducer;
