import { createSlice } from "@reduxjs/toolkit";
 
export const serviceBookingSlice = createSlice({
  name: "serviceBookingSlice",
  initialState: {
    bookingListing: [],
    filteredList: [],
    filterTerm: '',
    clickSave: {},
    singleData: {},
    totalEntry: 0 
  },          
  reducers: {   
    setDataList: (state, action) => {
      state.bookingListing = action.payload;
    },
    setSingleData: (state, action) => {
      state.singleData = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },
    setTotalEntry: (state, action) => {
        state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setPhoto: (state, action) => {
      state.myFile = action.payload;
    },
    setClickData: (state, action) => {
      state.clickSave = action.payload;
    },

  }, 
}); 

export const {
    setDataList, 
    setSingleData,
    setFilterTerm,
    setClickData,
    setTotalEntry,
    setFilteredList,
    setPhoto,
} = serviceBookingSlice.actions;

export const selectStateData = (state) => state.serviceBooking.bookingListing; 
export const selectFilterTerm = (state) => state.serviceBooking.filterTerm; 
export const selectTotalEntry = (state) => state.serviceBooking.totalEntry; 
export const selectFilteredList = (state) => state.serviceBooking.filteredList; 
export const selectStateDetail = (state) => state.serviceBooking.clickSave;
export const selectStateSingleData = (state) => state.serviceBooking.singleData; 


export default serviceBookingSlice.reducer;
