import React from 'react';

import { 
    Title,
    RadioGroup,
    Radio,
    Card
} from '@mantine/core';

const RadioElement = () => (
    <Card 
        shadow="sm" 
        p="lg"
        sx={_ => ({
            maxWidth: 450,
            minWidth: 450,
            minHeight: 250,
            '@media (max-width: 1160px)': {
                minWidth: 'auto',
                width: 380
            },
            '@media (max-width: 1050px)': {
                width: '90%',
                margin: '0 auto'
            }
        })}
    >
            <Title order={3} style={{marginBottom: 30}}>
                Radio Button
            </Title> 
            <RadioGroup
                label="Select your favorite framework/library"
                description="This is anonymous"
                required
                >
                <Radio value="react" label="React" />
                <Radio value="svelte" label="Svelte" />
                <Radio value="ng" label="Angular" />
                <Radio value="vue" label="Vue" />
            </RadioGroup>
        </Card>
    )

export default RadioElement