import React, { useState, useEffect } from 'react';
import { Menu, Button, Image, Textarea, Modal, Group, Box, TextInput, ActionIcon } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash, FiEye, FiMoreVertical, } from "react-icons/fi";

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';
import FileUpload from './FileUploader';

// API-Service  
import { mechanicListService, mechanicUpdateService, mechanicRemoveService } from '../../../../service/mechanic.service';

// Redux-Settings
import { setMechanicServiceList, setTotalEntry, setFilteredList, setClickData, selectMechanicServiceDetail } from '../../../../redux/mechanicServiceSlice';

const DeleteUpdate = ({ tableRecord }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [file, setFile] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [showImage, setImage] = useState();
  const [formValue, setFromValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const detail = useSelector(selectMechanicServiceDetail);
  const token = getToken();

  // All Record-update Controller
  const hendleUpdateModal = () => {
    setIsUpdateModal(true);
    dispatch(setClickData())
  };

  const changeHandler = (event) => {
    const myFile = event.target.files[0]
    setFile(myFile)
    setImage(URL.createObjectURL(file))
  };

  const changeSelectedFile = (event) => {
    const myFile = event.target.files[0]
    setSelectedFile(myFile)
    // setImage(URL.createObjectURL(file))
  };
  useEffect(() => {
    setFromValue(tableRecord);
  }, [tableRecord]);


  const handleTitleChange = (e) => {
    setFromValue((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
    setIsUpdateModal(false);
  };

  const hendleDelete = async () => {
    const result = await mechanicRemoveService({
      id: tableRecord?.id,
      token,
    });
    setIsDeleteModal(false);
    if (result?.data?.status) {
      SuccessToast(result.data.message)
      const resultAll = await mechanicListService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setMechanicServiceList(resultAll?.data?.data?.mechanic_services_list));
        dispatch(setFilteredList(resultAll?.data?.data?.mechanic_services_list));
        dispatch(setTotalEntry(result?.data?.data?.mechanic_services_list.length));

      } else {
        ErrorToast(resultAll.data.message);
      }
    }
    // setIsDeleteModal(false)
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    if (file) {
      formData.append('image', file)
    }
    if (selectedFile) {
      formData.append('selected_image', selectedFile)
    }
    formData.append('id', formValue?.id)
    formData.append('name', formValue?.name)
    formData.append('token', token)
    formData.append('_method', 'PUT')
    const result = await mechanicUpdateService(
      // {id: formValue?.id,name: formValue?.name,file: formValue?.image,token,}
      { token, },
      formData
    );

    if (result?.data?.status) {
      const resultAll = await mechanicListService({ page: 1, countPerPage: 10, token });

      if (resultAll?.data?.status) {
        dispatch(setMechanicServiceList(resultAll?.data?.data?.mechanic_services_list));
        dispatch(setFilteredList(resultAll?.data?.data?.mechanic_services_list));
        dispatch(setTotalEntry(result?.data?.data?.mechanic_services_list?.length));
        SuccessToast(result.data.message)
        setIsUpdateModal(false);
      } else {
        ErrorToast(resultAll.data.message);
      }
    }
    setIsUpdateModal(false);
  };

  return (
    <Box>
      <Menu control={<ActionIcon><FiMoreVertical size={18} /></ActionIcon>}>
        <Menu.Item onClick={hendleUpdateModal} icon={<FiEdit size={14} />}>
          Edit
        </Menu.Item>
        <Menu.Item
          onClick={hendleDeleteModal}
          icon={<FiTrash size={14} />}>
          Delete
        </Menu.Item>
      </Menu>

      <Modal opened={isDeleteModal} onClose={() => setIsDeleteModal(false)} title="Are you sure want to delete ?" centered>
        <Group position="center">
          <Button variant="outline" onClick={hendleCancel}>
            Cancel
          </Button>
          <Button color="yellow" onClick={hendleDelete}>Delete</Button>
        </Group>
      </Modal>

      <Modal opened={isUpdateModal} onClose={() => setIsUpdateModal(false)} title="Update Service" centered>
        <form onSubmit={(values) => handleUpdate(values)}>
          <TextInput
            required
            label="Name"
            value={formValue?.name || ''}
            onChange={handleTitleChange} />

          <div>
            {formValue.image ? <div style={{ marginTop: 10 }}>

              <Image src={formValue?.image}
                alt="Service Image"
                sx={(_) => ({
                  height: '80px',
                  width: '80px',
                  overflow: 'hidden',
                })} />
            </div> : ''}


            <div style={{ marginTop: 10 }}>
              <TextInput label="Select Photo" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeHandler} />
            </div>
          </div>
          <div>
            {formValue.selected_image ? <div style={{ marginTop: 10 }}>

              <Image src={formValue?.selected_image}
                alt="Selected Image"
                sx={(_) => ({
                  height: '80px',
                  width: '80px',
                  overflow: 'hidden',
                })} />
            </div> : ''}

            <div style={{ marginTop: 10 }}>
              <TextInput label="Select Photo" type="file" name="file" accept="image/png, image/jpg, image/jpeg" onChange={changeSelectedFile} />
            </div>
          </div>

          <Group position="center" mt="md">
            <Button color="dark" onClick={hendleCancel}>
              Cancel
            </Button>
            <Button color="yellow" type="submit">Update</Button>
          </Group>
        </form>
      </Modal>
    </Box>
  );
};

export default DeleteUpdate;
