import React, { useState, useEffect } from 'react';
import { Card, Title, Text, Image, Button } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from '../Loading/Loading';
import { selectFilteredPostList } from '../../redux/postSlice';

const PostDetails = () => {
    const { state }  = useLocation();
    const navigate = useNavigate();
    const handleReturn = _ => navigate('/')

    return(
        <Card 
        sx={ _=> ({
            minHeight: 250,
            width: 850,
            alignItems: 'center',
            margin: '0 auto', 
            '@media (max-width: 912px)': {
                width: '80%'
            },
            '@media (max-width: 612px)': {
                width: '90%'
            }
        })}>
            <Image src='https://picsum.photos/id/237/200/300' alt='Stable picture' />
            <Title style={{marginTop: 30}}>{state?.title}</Title>
            <Text style={{marginTop: 30}}>{state?.body}</Text>
            <Button style={{marginTop: 30}} onClick={handleReturn}>Return</Button>
        </Card>
    )
}

export default PostDetails