import React from 'react';
import { Navbar } from '@mantine/core';

import NavItems from './NavItem'

const NavBar = ({opened}) => (
      <Navbar 
        height='auto'
        p="xs" 
        hiddenBreakpoint="sm" 
        hidden={!opened} 
        width={{ sm: 200, lg: 250 }}
        position={{ top: 0, left: 0 }}
        sx={() => ({
          '@media (max-width: 800px)': {
            position: 'fixed',
            width: '200px',
            height:'100%',
            top: '80px'
        }  
        })}
      >
        <Navbar.Section grow mt="md">
          <NavItems />
        </Navbar.Section>
      </Navbar>
    );
  

export default NavBar;