import React from 'react';
import ForgetPassword from '../../../components/Auth/ForgetPassword'


function Password() {
    return (
        <div>
            <div>
            <ForgetPassword />
            </div>
        </div>
    );
}
export default Password;