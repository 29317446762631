import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT
// Breakdown Booking List
export const jobComplainList = ({ page, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/breakdown-report/list?paginate=1&page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  }); 
 // Service Booking List 
  export const serviceComplainList = ({ page, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/service-report/list?paginate=1&page=${page}&limit=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  }); 
export const jobComplainSearchList = ({ search, countPerPage, token }) =>
  axios.get(`${baseUrl}/admin/breakdown-report/list?paginate=1&keyword=${search}&perPage=${countPerPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
// Breakdown - Complain
export const complainIsResolve = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/breakdown-report/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );
// Service - Complain
  export const complaintIsResolve = ({ id, token }) =>
  axios.post(
    `${baseUrl}/admin/service-report/status/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );


export const jobComplainDelete = ({ id, token }) =>
  axios.delete(
    `${baseUrl}/admin/breakdown-report/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  );