import React, { useState, useEffect } from 'react';
import { Menu, Button, Image, Textarea, Modal, Col, Box, Grid, ScrollArea, ActionIcon, Card } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconCheck, IconX } from '@tabler/icons';
import { FiEdit, FiTrash, FiEye, FiMoreVertical } from "react-icons/fi";


// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// API-Service  
import { userContactListingService, userContactDataRemoveService } from '../../../../service/contactService';
  
// Redux-Settings
import { 
  setUserContactList,
  setUserTotalEntry,
  selectStateUserContact,
} from '../../../../redux/contactSlice';

const UserMessage = ({ tableRecord }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isNotification, setNotification] = useState(false);

  const [formValue, setFromValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detail = useSelector(selectStateUserContact);
  const token = getToken();

  // All Record-update Controller
  // const hendleUpdateModal = () => {
  //   navigate(`/admin/edit-coupon/${tableRecord?.id}`)

  //   dispatch(setClickData())
  // };

  useEffect(() => {
    setFromValue(tableRecord);
  }, [tableRecord]);

  const hendleDeleteModal = () => {
    setIsDeleteModal(true);
  };

  const hendleCancel = () => {
    setIsDeleteModal(false);
  };

  const hendleDelete = async () => {
    const result = await userContactDataRemoveService({
      id: tableRecord?.id,
      token,
    });
    // setIsDeleteModal(false);
    if (result?.data?.status) {
      SuccessToast(result.data.message)
      const resultAll = await userContactListingService({ filterTerm: 'Active', page: 1, countPerPage: 10, token });
      if (resultAll?.data?.status) {
        dispatch(setUserContactList(resultAll?.data?.data?.coupon_lists));
        dispatch(setUserTotalEntry(result?.data?.data?.pagination?.total));
        // SuccessToast(resultAll.data.message)
      } else {
        ErrorToast(resultAll.data.message);
      }
    }
    // setIsDeleteModal(false)
  };

  return (
    <Box>
        <Button
          onClick={hendleDeleteModal}
        >
          View
        </Button>
      

        <Modal
        opened={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        title="Message"
        centered
        size="55%"
        scrollAreaComponent={ScrollArea.Autosize}
        style={{ maxHeight: '600px' }}  
      >
          <Card>
          <Grid style={{background: '#EFF4FF', padding: '12px'}}>
            <Col >
            {tableRecord?.message || '--'}
            </Col>
            </Grid>
          </Card>  
      </Modal>
      </Box>
  );
};

export default UserMessage;
