import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { useForm, UseFormReturnType } from '@mantine/form';
import { Box, Card, Image, TextInput, PasswordInput, Button, Title, Group, Loader, Notification } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { FiX, FiBell } from "react-icons/fi";
// import { useForm } from 'react-hook-form';

// Utility-Service
import { getToken, setUserSession } from '../../utils/AuthService';
import { ErrorToast, SuccessToast, CatchErrorToast } from '../../utils/utilities';

import { loginService } from '../../service/user'
import { setLoginDetails } from '../../redux/userSlice';
// import Loading from '../Loading/Loading';

/*
    Props-Function: 
    1. logo props for logo-image path
        > Value-type: string
    2. LogoVariantInside for set logo in inside of Card Component
        >  Value-type: Boolean
    2. LogoVariantOutside for set logo in outside of Card Component
        >  Value-type: Boolean
*/

const Login = ({ logo = null, LogoVariantInside = false, LogoVariantOutside = false, shadow = 'lg' }) => {
  const navigate = useNavigate();
  const token = getToken();
  useEffect(() => {
    if (token) {
      // console.log(token)
      navigate('/admin/dashboard');
    }
  }) 
  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validate: {
      email: (value) => (value.length < 2 ? 'Please enter email' : null),
      password: (value) => (value.length < 2 ? 'Please enter password' : null),
    },

  });

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async (values, e) => {
    // console.log('myData', values, e)
    // e.preventDefault();
    setLoading(true);
    const result = await loginService({
      email: values.email,
      password: values.password
    })
    .catch((error) => {
      console.log('MyError', error.response.data.message)
      CatchErrorToast(error);
      });
    if (result?.data?.status) {
      setLoading(false)
      ErrorToast(result); 
      if (result?.data?.user) {
        setLoading(false)
        SuccessToast(result.data.message);
        setUserSession(result?.data?.token, result?.data?.user);
        dispatch(setLoginDetails(result?.data))
        localStorage.setItem('auth', JSON.stringify(result?.data));
        navigate('/');
      }
      else {
        console.log('failed true1', result?.data?.message)
        // ErrorToast(error.response.data.message);
        setLoading(false)
      }
    }
    else {
      console.log('failed true1', result?.data?.message)
        // ErrorToast(result?.data?.message);
        setLoading(false)
    }
    return false;
  }

  if (loading) {
    return <Box style={{ width: '100%' }}>
      <div style={{
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}><Loader color="yellow" /></div>
    </Box>
  }


  return (
    <Box style={{ width: '100%' }}>
      { showError ? <Notification icon={<FiX size={18} />} color="red">
        Please enter correct credential
      </Notification> : ''}
      {logo && LogoVariantOutside && (
        <Image
          style={{
            width: '110px',
            margin: '50px auto'
          }}
          src={logo}
          alt="Dashboard Logo"
        />
      )}
      <Card style={{ width: '100%' }} shadow={!shadow ? shadow : 'lg'} padding="xl">
        {logo && LogoVariantInside && (
          <Image
            style={{
              width: '110px',
              margin: '30px auto'
            }}
            src={logo}
            alt="Dashboard Logo"
          />
        )}
        {logo && !LogoVariantInside && !LogoVariantOutside && (
          <Image
            style={{
              width: '110px',
              margin: '30px auto'
            }}
            src={logo}
            alt="Dashboard Logo"
          />
        )}

        <Title
          style={{
            margin: '14px auto',
            textAlign: 'center'
          }}
          order={3}
        >
          Log In
        </Title>

        <form onSubmit={form.onSubmit((values, e) => handleLogin(values, e))}>
          <TextInput label="Email" type="email" placeholder="example@mail.com" {...form.getInputProps('email', { required: true })} />

          <PasswordInput
            label="Password"
            placeholder="Password"
            autoComplete="on"
            required
            mt="sm"
            {...form.getInputProps('password', {
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
            })}
          />
          <Group position="right" mt="xl">
            <Button
              variant="filled"
              type="submit"
              styles={(theme) => ({
                root: {
                  transition: 'all 0.3s ease-in',
                  marginTop: 20,
                  '&:hover': {
                    backgroundColor: theme.fn.darken('#228be6', 0.05)
                  }
                }
              })}
              fullWidth
            >
              { loading? <Loader color="yellow"/> : 'Login'}
            </Button>
          </Group>
        </form>
      </Card>
    </Box>
  );
};

export default Login;

Login.prototypes = {
  children: PropTypes.string
};
