import { createSlice } from "@reduxjs/toolkit";
 
export const mechanicServiceSlice = createSlice({
  name: "mechanic",
  initialState: {
    mechanicServiceList: [],
    filteredList: [],
    filterTerm: '',
    myFile: '',
    clickSave: {},
    totalEntry: 0
  },     
  reducers: {  
    setMechanicServiceList: (state, action) => {
      state.mechanicServiceList = action.payload;
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload;
    },
    setTotalEntry: (state, action) => {
        state.totalEntry = action.payload;
    },
    setFilteredList: (state, action) => {
      state.filteredList = action.payload;
    },
    setMechanicPhoto: (state, action) => {
      state.myFile = action.payload;
    },
    setClickData: (state, action) => {
      state.clickSave = action.payload;
    },

  },
});

export const {
    setMechanicServiceList, 
    setFilterTerm,
    setClickData,
    setTotalEntry,
    setFilteredList,
    setMechanicPhoto,
} = mechanicServiceSlice.actions;

export const selectMechanicServiceList = (state) => state.mechanicService.mechanicServiceList; 
export const selectFilterTerm = (state) => state.mechanicService.filterTerm; 
export const selectTotalEntry = (state) => state.mechanicService.totalEntry; 
export const selectFilteredList = (state) => state.mechanicService.filteredList; 
export const selectMechanicServiceDetail = (state) => state.mechanicService.clickSave;
export const selectMechanicPhoto = (state) => state.mechanicService.setMechanicPhoto; 


export default mechanicServiceSlice.reducer;
