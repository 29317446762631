
import React from 'react';

import { 
    Box,
    Title,
    Checkbox,
    Card
} from '@mantine/core';

const CheckboxElement = () => (
    <Card 
        shadow="sm" 
        p="lg"
        sx={_ => ({
            maxWidth: 450,
            minWidth: 450,
            minHeight: 250,
            '@media (max-width: 1160px)': {
                minWidth: 'auto',
                width: 380
            },
            '@media (max-width: 1050px)': {
                width: '90%',
                margin: '0 auto'
            }
        })}
    >
        <Title order={3} style={{marginBottom: 30}}>
            Checkbox
        </Title> 
        <Box style={{
            display: 'flex', 
            flexWrap: 'wrap',
            gap: '16px'
        }}>
            <Checkbox onChange={() => true} label="I agree to sell my privacy"/>
            <Checkbox onChange={() => true} checked={false} label="Default checkbox" />
            <Checkbox onChange={() => true} checked={false} indeterminate label="Indeterminate checkbox" />
            <Checkbox onChange={() => true} checked label="Checked checkbox" />
            <Checkbox onChange={() => true} disabled label="Disabled checkbox" />
            <Checkbox onChange={() => true} disabled checked label="Disabled checked checkbox" />
            <Checkbox onChange={() => true} disabled indeterminate label="Disabled indeterminate checkbox" />
        </Box>
    </Card>
)

export default CheckboxElement