
import React from 'react'
import { Box, Card } from '@mantine/core';

import ListingTable from './ListingTable';
// import CreateMechanic from './Create';
 import FilterMenu from './Filter';
import SearchForm from "./Search";
import Footer from '../../../../components/Footers/Footer';
         
const Coupon = () => (
        <Card>
            <Box sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '20px 0',
                borderTop: '1px solid', 
                borderBottom: '1px solid',
                borderColor: theme.fn.darken('#dee2e6', 0.05),
                marginBottom: '50px',
                '@media (max-width: 912px)': {
                    justifyContent: 'center',
                    flexDirection: 'column'
                }
            })}>
                <SearchForm />
                <Box style={{ display: 'flex', gap: '16px' }}>
                    <FilterMenu />
                </Box>
            </Box>
            <ListingTable />
            {/* <Footer /> */}
        </Card>
    )

export default Coupon
