import React, { useEffect, useState } from 'react';
import { Input } from '@mantine/core';
import { Search } from 'tabler-icons-react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getToken, removeUserSession } from '../../../../utils/AuthService';

// API-Service
import { userSearchService } from '../../../../service/topupService';

// Redux-Settings
import { setDataList, setTotalEntry } from '../../../../redux/topupSlicer';

const SearchForm = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState();

    const token = getToken();
    const navigate = useNavigate();

    const [pageCount, setPageCount] = useState(1);
    const countPerPage = 10;

    const fetchData = async (abc) => {
        const result = await userSearchService({ search: abc, countPerPage, token });
        if (result?.data?.status) {
            setLoading(false);
            dispatch(setDataList(result?.data?.data?.topup_lists));
            dispatch(setTotalEntry(result?.data?.data?.pagination?.total));

        }
    };

    const inputHandler = (e) => {
        setSearch(e.target.value.toLowerCase())
        if (e.target.value) {
            fetchData(e.target.value);

        }
    };

    return (
        <Input
            onChange={inputHandler}
            icon={<Search size={16} />}
            placeholder="Search By Amount"
            style={{ minWidth: '300px' }}
            sx={() => ({
                '@media (max-width: 912px)': {
                    marginBottom: '30px'
                }
            })}
        />
    )
}

export default SearchForm;