
import React from 'react';

import {
    Title,
    ColorInput ,
    Card
} from '@mantine/core';

const ColorInputElement = () => 
    (<Card 
        shadow="sm" 
        p="lg"
        sx={_ => ({
            maxWidth: 450,
            minWidth: 450,
            minHeight: 250,
            '@media (max-width: 1160px)': {
                minWidth: 'auto',
                width: 380
            },
            '@media (max-width: 1050px)': {
                width: '90%',
                margin: '0 auto'
            }
        })}
    >
        <Title order={3} style={{marginBottom: 30}}>
            Color Input
        </Title> 
        <ColorInput placeholder="Pick color" label="Your favorite color" />
        <ColorInput format="hex" label="Listed color" swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}/>
    </Card>)

export default ColorInputElement