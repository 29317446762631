
import React from 'react';

import { 
    Box,
    Title,
    Chips,
    Chip,
    createStyles,
    Card
} from '@mantine/core';




const ChipsElement = () => {
    
    const useStyles = createStyles((theme, _params, getRef) => ({
        iconWrapper: {
          ref: getRef('iconWrapper'),
        },
      
        checked: {
          backgroundColor: `${theme.colors.blue[6]} !important`,
          color: theme.white,
      
          [`& .${getRef('iconWrapper')}`]: {
            color: theme.white,
          },
        },
    }));
    const { classes } = useStyles();
    return(

        <Card 
            shadow="sm" 
            p="lg"
            sx={_ => ({
                maxWidth: 450,
                minWidth: 450,
                minHeight: 250,
                '@media (max-width: 1160px)': {
                    minWidth: 'auto',
                    width: 380
                },
                '@media (max-width: 1050px)': {
                    width: '90%',
                    margin: '0 auto'
                }
            })}
        >
            <Title order={3} style={{marginBottom: 30}}>
                Chips
            </Title> 
            <Box style={{
                display: 'flex', 
                flexWrap: 'wrap',
                gap: '16px'
            }}>
                <Chips>
                    <Chip value="react">React</Chip>
                    <Chip value="ng">Angular</Chip>
                    <Chip value="svelte">Svelte</Chip>
                    <Chip value="vue">Vue</Chip>
                </Chips>
                <Chips position="center" multiple classNames={classes} defaultValue={['react']}>
                    <Chip value="react">React</Chip>
                    <Chip value="ng">Angular</Chip>
                    <Chip value="vue">Vue</Chip>
                    <Chip value="svelte">Svelte</Chip>
                </Chips>
            </Box>
        </Card>
    )}

export default ChipsElement