
import React from 'react';
import { Settings  } from 'tabler-icons-react';

import { 
    Box,
    Title,
    ActionIcon,
    Card
} from '@mantine/core';

const ActionIconElement = () => (
    <Card 
        shadow="sm" 
        p="lg"
        sx={_ => ({
            maxWidth: 450,
            minWidth: 450,
            minHeight: 250,
            '@media (max-width: 1160px)': {
                minWidth: 'auto',
                width: 380
            },
            '@media (max-width: 1050px)': {
                width: '90%',
                margin: '0 auto'
            }
        })}
    >
    
        <Title order={3} style={{marginBottom: 30}}>
            ActionIcon
        </Title> 
        <Box style={{
                display: 'flex', 
                flexWrap: 'wrap',
                gap: '16px'
        }}>
            <ActionIcon variant="transparent"><Settings size={16} /></ActionIcon>
            <ActionIcon variant="hover"><Settings size={16} /></ActionIcon>
            <ActionIcon variant="default"><Settings size={16} /></ActionIcon>
            <ActionIcon variant="outline"><Settings size={16} /></ActionIcon>
            <ActionIcon variant="filled"><Settings size={16} /></ActionIcon>
            <ActionIcon variant="light"><Settings size={16} /></ActionIcon>
            <ActionIcon color="red"><Settings size={16} /></ActionIcon>
            <ActionIcon color="blue"><Settings size={16} /></ActionIcon>
        </Box>
    </Card>
)

export default ActionIconElement