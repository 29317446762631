import axios from 'axios';

const baseUrl = process.env.REACT_APP_APP_API_ENDPOINT

//  Freelancer Plans
export const PlanListingService = ({token }) =>
  axios.get(`${baseUrl}/admin/mechanic-variable/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },  
  });

  export const CreateDataService = ({ token }, strings) =>
  axios.post(
    `${baseUrl}/admin/mechanic-variable/store`, strings,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

  export const PlanListingServiceCustomer = ({token }) =>
  axios.get(`${baseUrl}/admin/mechanic-variable/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },  
  });

  export const CreateDataServiceCustomer = ({ token }, strings) =>
  axios.post(
    `${baseUrl}/admin/mechanic-variable/store`, strings,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );

  export const CreateDataServiceDistance = (data, {token} ) =>
  axios.post(
    `${baseUrl}/admin/mechanic-variable/store`, {"data" : data},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'multipart/form-data'
        // 'application/json',
      },
    }
  );
