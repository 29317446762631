import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import * as yup from 'yup'
import { Card, Row, Col, Button, Input, FormFeedback, Form, InputGroup, InputGroupText } from 'reactstrap'

// import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { setNumber } from '../../../../redux/deleteAccount/otpSlice'
import { genrateOtpService } from '../../../../service/otpVerification/otpService'
import Logo from '../../../../assets/images/logo.png'
import { ErrorToast, SuccessToast } from '../../../../utils/utilities'

const DeletePage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const formValidation = yup.object().shape({
        phone: yup
            .string()
            .required('Please enter phone number')
            .min(9, 'Phone number should contain 9 to 12 digits')
            .max(12, 'Phone number should contain 9 to 12 digits')
    })

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        // resolver: yupResolver(formValidation)
    })

    const handleOtp = async (data) => {
        try {
            setLoading(true)
            let { phone } = data

            if (!phone || phone.length < 9 || phone.length > 12) {
                setLoading(false)
                ErrorToast('Please fill valid phone number')
                return
              }

            phone = phone.replace(/^\+6/, '')
            const result = await genrateOtpService({ phone }).catch((error) => {
                setLoading(false)
                SuccessToast(error.response.data.message)
            })

            if (result.data.status === 'success') {
                setLoading(false)
                dispatch(setNumber(phone))
                navigate('/otp-verification')
            } else {
                setLoading(false)
                ErrorToast(result.data.message)
            }
        } catch (error) {
            setLoading(false)
            console.error('Error during OTP request:', error)
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <div className="p-3">
                        <div className="d-flex flex-column align-items-center justify-content-center text-center">
                            <img src={Logo} alt="logo" width="100px" height="100px" />
                            <h2 className="mt-2">
                                <span className="d-block d-md-inline">Arc </span>
                                <span className="d-block d-md-inline">App</span>
                            </h2>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="p-2 pt-1 h-100 mx-auto">
                <Col md="12" sm="12" className="ms-1">
                    <h2> Arc Account Deletion</h2>
                </Col>
                <Col md sm="12" className="ms-1 mb-2 pe-2">
                    <Card className="h-100" style={{ border: 'none' }}>
                        <div className=" p-2 bg-white h-100">
                            <h5 className="mb-2">
                                These data will be deleted when you delete the account
                            </h5>
                            <ul className="deletion-data">
                                <li>Breakdown Booking</li>
                                <li>Service Booking</li>
                                <li>Subscription</li>
                                <li>Wallet</li>
                                <li>Notification</li>
                                <li>Chat</li>
                                <li>Bank Account</li>
                                <li>Booking History</li>
                            </ul>
                        </div>
                    </Card>
                </Col>
                <Col md sm="12" className="ms-1 mb-2 pe-2">
                    <Card className="h-100" style={{ border: 'none' }}>
                        <div className="p-3 bg-white h-100">
                            <h5 className="mb-5">
                                Enter your Registered{' '}
                                <span className="text-primary">Phone number</span> to{' '}
                                delete your Arc account
                            </h5>

                            <Form onSubmit={handleSubmit(handleOtp)}>
                                <div className="d-flex flex-column mt-2">
                                    <InputGroup >
                                        <InputGroupText className="mb-50 p-2 border"  >+6</InputGroupText>
                                        <Controller
                                            render={({ field }) => (
                                                <Input
                                                    type="text"
                                                    className="mb-50 p-2 border"
                                                    {...field}
                                                    placeholder="Enter here"
                                                    invalid={!!errors.phone}
                                                    style={{ outline: 'none' }}
                                                />
                                            )}
                                            name="phone"
                                            control={control}
                                        />
                                    </InputGroup>
                                   
                                    <FormFeedback>
                                        {errors.phone && (
                                            <>
                                                {errors.phone.type === 'required' && 'Please enter phone number'}
                                                {errors.phone.type === 'min' && 'Phone number should contain at least 9 digits'}
                                                {errors.phone.type === 'max' && 'Phone number should contain at most 12 digits'}
                                            </>
                                        )}
                                    </FormFeedback>


                                    <div className="align-self-end">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="px-5 mt-1"
                                            disabled={loading}
                                        >
                                            {loading ? 'Verifying...' : 'Verify'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default DeletePage
