import React from 'react';

import { 
    Title,
    Slider,
    Card
} from '@mantine/core';

const SliderElement = () => {
    const marks = [
        { value: 20, label: '20%' },
        { value: 50, label: '50%' },
        { value: 80, label: '80%' },
    ];

    return(
        <Card 
            shadow="sm" 
            p="lg"
            sx={_ => ({
                maxWidth: 450,
                minWidth: 450,
                minHeight: 250,
                '@media (max-width: 1160px)': {
                    minWidth: 'auto',
                    width: 380
                },
                '@media (max-width: 1050px)': {
                    width: '90%',
                    margin: '0 auto'
                }
            })}
        >
            <Title order={3} style={{marginBottom: 30}}>
                Slider
            </Title> 
            <Slider
                marks={[
                    { value: 20, label: '20%' },
                    { value: 50, label: '50%' },
                    { value: 80, label: '80%' },
                ]}
            />
        </Card>
    )}

export default SliderElement