import React, { useState, useEffect } from 'react';
import { Box, Image, Switch, Avatar } from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


import Loading from '../../../../components/Loading/Loading';

// Utility-Service
import { getToken, removeUserSession } from '../../../../utils/AuthService';
import { ErrorToast, SuccessToast } from '../../../../utils/utilities';

// Delete and Update
import DeleteUpdate from './DeleteUpdate';

// API-Service
import { userListService, userStatusUpdateService } from '../../../../service/user';

// Redux-Settings
import {
  setUserList,
  setTotalEntry,
  selectFilterTerm,
  selectUserList,
  setFilteredList,
  selectFilteredList,
  selectTotalEntry
} from '../../../../redux/userSlice';

// Auth-Image
import AuthImage from '../../../../components/AuthImage/AuthImage';

import PlaceHolderThumb from '../../../../assets/images/placeholderThumb.png';


const ListingTable = () => {
  const filterTag = useSelector(selectFilterTerm);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const Listing = useSelector(selectUserList);
  const totalEntry = useSelector(selectTotalEntry);
 console.log('Listing', Listing)
  const token = getToken();
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const countPerPage = 10;

  const fetchData = async (filterTerm, page) => {
    const result = await userListService({ countPerPage, page, token });
    if (result?.data?.status) {
      const responseData = result?.data?.data?.customers
      setLoading(false);
      dispatch(setUserList(responseData));
      dispatch(setFilteredList(responseData));
      dispatch(setTotalEntry(result?.data?.data?.pagination?.total));
    } else {
      setLoading(false);
      ErrorToast(result);
    }
  };

  const statusUpdate = async (rowRecord) => {
    const statusUpdateresult = await userStatusUpdateService({ id: rowRecord.id, token });
    if (statusUpdateresult?.data?.status) {
      fetchData(filterTag, pageCount);
      SuccessToast(statusUpdateresult.data.message)
    } else {
      ErrorToast(statusUpdateresult);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(filterTag, pageCount);
    const timeoutId = setTimeout(() => 3000);
    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, [filterTag, pageCount]);

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id ? row.id : '--',
      maxWidth: '450px',
    },

    // {
    //   name: 'Profile',
    //   selector: (row) => (
    //     row?.profile_image ? (
    //       <AuthImage 
    //       src={row?.profile_image} 
    //       sx={(_) => ({
    //         height: '60px',
    //         width: '60px',
    //         overflow: 'hidden',
    //         marginTop: '30px'
    //       })}
    //       />
    //     ) : (
    //       <Image
    //         src={PlaceHolderThumb}
    //         alt="Avatar Thumb"
    //         sx={(_) => ({
    //           height: '60px',
    //           width: '60px',
    //           overflow: 'hidden',
    //         })}
    //       />
    //     )),
    // },
    {
      name: 'Profile',
      selector: (row) => (
        (
          <Avatar
            radius={10}
            size={60}
            src={row?.profile_image || PlaceHolderThumb}
            alt="Profile"
            href={row?.profile_image}
            target="_blank"
            component="a"
            style={{margin : '5px 0'}}
          />

        )),
    },

    {
      name: 'Name',
      selector: (row) => row.name ? row.name : '--',
      minWidth: '120px'
    },
    // {
    //   name: 'Email',
    //   selector: (row) => row.email ? row.email : '--',
    //   sortable: true,
    //   maxWidth: '450px',
    //   minWidth: '250px'
    // },
    {
      name: 'Phone',
      selector: (row) => row.phone ? row.phone : '--',
      sortable: true,
      minWidth: '170px'
    },
    {
      name: 'Language',
      selector: (row) => row.language_name ? row.language_name : '--',
      sortable: true,
      minWidth: '200px'
    },
    {
      name: 'Wallet',
      selector: (row) => row.wallet_balance?.wallet_balance ? `RM ${row.wallet_balance?.wallet_balance}` : '--',
      sortable: true,
      minWidth: '150px'
    },
    {
      name: 'Status',
      //   onChange={() => statusUpdate(row)}
      selector: (row) => (
        <Switch
          checked={row.status === 1}
          onChange={() => statusUpdate(row)}
          onLabel="Enable"
          offLabel="Disable"
          size="md"
          color="yellow"
        />
      ),
      minWidth: '100px',
    },
    {
      name: 'Created Date',
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      minWidth: '100px'
    },

  ];


   // ** Function to handle Pagination
   const handlePagination = page => {
    setCurrentPage(page.selected)
   }

   // ** Custom Pagination
   const CustomPagination = () => (
    <ReactPaginate
        nextLabel={<div className='d-inline-flex align-items-center bg-white p-50 border border-2 rounded p-1'>
            <span className='fs-14 fw-bold me-2 text-dark '>Next</span>
            <AiOutlineArrowRight className='text-secondary' size={18} />
        </div>}
        breakLabel='...'
        previousLabel={<div className='d-inline-flex align-items-center bg-white p-50  border border-2 rounded p-1'>
            <AiOutlineArrowLeft className='text-secondary me-2' size={18} />
            <span className='fs-14 fw-bold text-dark' >Previous</span>
        </div>}
        pageRangeDisplayed={3}
        forcePage={currentPage}
        marginPagesDisplayed={3}
        activeClassName='active bg-light border rounded px-2 d-flex align-items-center text-dark border-3'
        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
        breakClassName='page-item me-3 d-flex align-items-center'
        nextClassName='page-item next-item flex-grow-1 text-end'
        previousClassName='page-item prev-item flex-grow-1 me-3'
        disabledLinkClassName='btn disabled p-0 border-0'
        pageCount={Math.ceil(totalEntry / 10) || 1}
        onPageChange={page => handlePagination(page)}
        containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-2 ps-1'
    />
)



  if (loading) {
    return (
      <Box
        sx={() => ({
          margin: '0 auto',
          minHeight: 440,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        })}
      >
        <span className="visually-hidden"><Loading /></span>
      </Box>
    );
  }

  return (
    <Box
      sx={() => ({
        minHeight: '100vh',
        '.rdt_TableCol_Sortable div:first-of-type': {
          whiteSpace: 'initial',
          textOverflow: 'initial',
          overflow: 'visible'
        },
        '.rdt_TableCell div:first-of-type': {
          whiteSpace: 'initial',
          padding: '5px 0'
        },
        '@media (max-width: 820px)': {
          overflow: 'scroll',
        },
      })}
    >
      <Box className="rui-filemanager-content" >
        <Box className="table-responsive-lg mb-20 inner-content-fix" > 
          <DataTable
            className="rui-datatable rui-filemanager-table table mb-20 mt-10" 
            columns={columns}
            data={Listing}
            key={Listing?.id}
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationTotalRows={totalEntry}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{ noRowsPerPage: true }}
            onChangePage={(page) => setPageCount(page)}
            paginationComponent={CustomPagination}
            paginationDefaultPage={currentPage + 1} 
          />
         
        </Box>
      </Box>
    </Box>
  );
};

export default ListingTable;
