import React from 'react';

import { Group, Avatar, Text, Accordion, Card, Title } from '@mantine/core';

    const charactersList = [
      {
        image: 'https://img.icons8.com/clouds/256/000000/futurama-bender.png',
        label: 'Bender Bending Rodríguez',
        description: 'Fascinated with cooking, though has no sense of taste',
        content: "Bender Bending Rodríguez, (born September 4, 2996), designated Bending Unit 22, and commonly known as Bender, is a bending unit created by a division of MomCorp in Tijuana, Mexico, and his serial number is 2716057. His mugshot id number is 01473. He is Fry's best friend.",
      },
    
      {
        image: 'https://img.icons8.com/clouds/256/000000/futurama-mom.png',
        label: 'Carol Miller',
        description: 'One of the richest people on Earth',
        content: "Carol Miller (born January 30, 2880), better known as Mom, is the evil chief executive officer and shareholder of 99.7% of Momcorp, one of the largest industrial conglomerates in the universe and the source of most of Earth's robots. She is also one of the main antagonists of the Futurama series.",
      }
    ]
    
  
const AccordionLabel = ({ label, image, description }) => (
    <Group noWrap>
      <Avatar src={image} radius="xl" size="lg" />
      <div>
        <Text>{label}</Text>
        <Text size="sm" color="dimmed" weight={400}>
          {description}
        </Text>
      </div>
    </Group>
  );

    
const AccordionElement = () => {
  const items = charactersList.map((item) => (
    <Accordion.Item label={<AccordionLabel {...item} />} key={item.label}>
      <Text size="sm">{item.content}</Text>
    </Accordion.Item>
  ));

  return (
    <Card 
      shadow="sm" 
      p="lg"
      sx={_ => ({
          maxWidth: 450,
          minWidth: 450,
          minHeight: 250,
          '@media (max-width: 1160px)': {
            minWidth: 'auto',
            width: 380
          },
          '@media (max-width: 1050px)': {
              width: '90%',
              margin: '0 auto'
          }
      })}
    >
      <Title order={3} style={{marginBottom: 30}}>
        Accordion 
      </Title> 
      <Accordion initialItem={-1} iconPosition="right">
        {items}
      </Accordion>
    </Card>
  );
  
}

export default AccordionElement;