import React from 'react';
import { Menu, Button } from '@mantine/core';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Redux-Settings
import { setFilterTerm } from '../../../../redux/bookingSlice';

const FilterMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const hendleAll = () => {
        dispatch(setFilterTerm("ALL"))
    }
    const hendleRequest = () => {
        dispatch(setFilterTerm("RELEASE_MECHANIC"))
    }
    const hendleOngoing = () => {
        dispatch(setFilterTerm("ONGOING"))
    }
    const hendleCancelled = () => {
        dispatch(setFilterTerm("CANCELED"))
    }
    const hendleCompleted = () => {
        dispatch(setFilterTerm("COMPLETED"))
    }
    // const hendlePending = () => {
    //     dispatch(setFilterTerm("TOWED"))
    // } 
    const justAdd = () => {
        navigate('/admin/add-coupon')
    };

    return (
        <>
            <Menu control={<Button variant="outline" color="dark">More Filter Options</Button>}>
                <Menu.Item onClick={hendleAll}>
                    All
                </Menu.Item>
                <Menu.Item onClick={hendleOngoing}>
                    On Going
                </Menu.Item>
                {/* <Menu.Item onClick={hendleCompleted}>
                    Completed
                </Menu.Item> */}
                <Menu.Item onClick={hendleRequest}>
                Release Mechanic
                </Menu.Item>
                {/* <Menu.Item onClick={hendlePending}>
                    Towed
                </Menu.Item> */}
                <Menu.Item onClick={hendleCancelled}>
                    Cancelled
                </Menu.Item>
            </Menu>

            {/* <Button color="yellow" className='' onClick={() => justAdd()}>Create Coupon</Button> */}
        </>
    )
}

export default FilterMenu