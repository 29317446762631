
import React, { useState } from 'react'

import Loading from '../../../components/Loading/Loading';
import CardLayout from '../../../components/CardLayout/CardLayout';
import Footer from '../../../components/Footers/Footer';



const Cards = () => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)


    if(loading){
        return <Loading/>
    }

    return(
        <>
            <CardLayout/>
            <Footer/>
        </>
    )
}
export default Cards
